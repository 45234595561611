import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Images/logo/logo.png";
import payment from "../../Images/other_image/payment.png";
const Footer = () => {
  return (
    <section className="">
      <footer className="footer p-10 bg-secondary justify-center md:justify-between text-base-content">
        <div className="text-white text-center">
          <img className="mx-auto" src={logo} alt="" />
          <p>
            <span className="font-bold text-xl pb-2">Shop In Shop</span>
            <br />
            <span>Authentic Indian Product Only</span>
          </p>
          <div className="mt-4">
            <a
              className="hover:text-orange-400"
              href="https://web.facebook.com/shopinshopbangladesh"
              target="_new"
            >
              <i className="fa-brands fa-facebook-f"></i>
            </a>
            <a
              className="mx-10 hover:text-orange-400"
              href="https://www.youtube.com/@shopinshopbangladesh"
              target="_new"
            >
              <i className="fa-brands fa-youtube"></i>
            </a>
            <a
              className="hover:text-orange-400"
              href="facebook.com/shopinshopbangladesh"
              target="_new"
            >
              <i className="fa-brands fa-twitter"></i>
            </a>
          </div>
        </div>
        <div className="text-slate-400">
          <span className="text-xl font-semibold text-white">Services</span>
          <Link to='/faqs' className="link link-hover">Help & FAQs</Link>
          <Link to='/blog' className="link link-hover">Blog</Link>
          <Link to='/profile/order' className="link link-hover">Order Tracking</Link>
          {/* <Link to='/profile/order' className="link link-hover">Shipping & Delivery</Link> */}
          <Link to='/profile/order' className="link link-hover">My Orders</Link>
          <Link to="/profile" className="link link-hover">
            My Account
          </Link>
        </div>
        <div className="text-slate-400">
          <span className="text-xl font-semibold text-white">Company</span>
          <Link to='/about' className="link link-hover">About SIS</Link>
          <Link to='/terms' className="link link-hover">Terms & Conditions</Link>
          <Link to='/shipping' className="link link-hover">Shipping Policy</Link>
          <Link to='/returnRefund' className="link link-hover">Return & Refund policy</Link>
          <Link to='/cancel' className="link link-hover">Cancel policy</Link>

          <Link to="/privacy" className="link link-hover">Privacy policy</Link>
          <Link to='/cookies' className="link link-hover">Cookie policy</Link>
          <Link to='/career' className="link link-hover">Career</Link>
        </div>
        <div className="text-white">
          <span className="text-xl font-semibold text-white ">
            Contact
          </span>
          <h2>ADDRESS:</h2>
          <span className="text-slate-400">
            <b> Present Address:</b><br />
            530/A, Baridhara-DOHS, Dhaka-1206,Bangladesh<br />
            <b> Registered address:</b><br />
            KA-43, Pragati Sarani,Shahjadpur,Gulshan,Dhaka 1212
          </span>
          <h2>PHONE:</h2>
          <span className="text-slate-400">+8801778057784</span>
          <h2>EMAIL:</h2>
          {/* <span className="text-slate-400">shopinshop.com.bd@gmail.com</span> */}
          <a className='text-slate-400' href={`mailto:shopinshop.com.bd@gmail.com`}>shopinshop.com.bd@gmail.com</a>
        </div>
      </footer>

      <div className="flex flex-wrap gap-4 bg-secondary  items-center p-4 justify-center md:justify-around text-neutral-content">
        <div className="items-center order-2 md:order-1 grid-flow-col">
          <p>Copyright © 2022 - Shop In Shop All right reserved</p>
        </div>
        <div className="order-1 md:order-2">
          <img className="h-20" src={payment} alt="payment method" />
        </div>
      </div>
    </section>
  );
};

export default Footer;
