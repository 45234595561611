import { useState } from "react";
import { useQuery } from "react-query";

const useProducts = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { isLoading, data, refetch } = useQuery(['products', currentPage], () =>
        fetch(`https://api.shopinshop.com.bd/product/client/public?page=${currentPage - 1}`)
            .then(res => res.json())
    );

    return [data, isLoading, setCurrentPage, currentPage, refetch]
}

export default useProducts;