import React from 'react';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use-media';
import useCategory from '../../Hooks/useCategory';
import preOrder from '../../Images/other_image/pre-order.png.jpeg';
import Spinner from '../../Shared/Spinner/Spinner';
const ShopCategory = () => {
    // const navigate = useNavigate();
    const isMobile = useMedia("(max-width: 600px)");
    // console.log(isMobile)
    const [data, isLoading] = useCategory();
    if (isLoading) {
        return <Spinner></Spinner>
    };

    const spcificCategory = data?.find(y => y?.name?.includes('Women')) || false;
    const HomepcificCategory = data?.find(y => y?.name?.includes('Home & Office')) || false;
    const MenSpcificCategory = data?.find(y => y?.name?.includes('Men')) || false;
    const PetSpcificCategory = data?.find(y => y?.name?.includes('Pet Care')) || false;
    const GadgetsSpcificCategory = data?.find(y => y?.name?.includes('Gadgets')) || false;
    const HomeSpcificCategory = data?.find(y => y?.name?.includes('Gourmet')) || false;
    const FestivalSpcificCategory = data?.find(y => y?.name?.includes('Express Offers')) || false;
    // console.log(spcificCategory)

    return (
        <div className='px-2 xl:px-5'>
            <div className='grid grid-cols-1 px-4 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-6 '>

                <div className="card bg-base-100 shadow-lg hover:shadow-2xl">
                    {
                        spcificCategory && <div className="card-body">
                            <h2 className="card-title text-lg font-bold">Shop Women's Collections</h2>
                            <div className='grid grid-cols-2 gap-4 mt-4'>
                                {
                                    spcificCategory?.category.slice(0, 4).map((sub, index) => sub.status && <div className='flex justify-center gap-4' key={index}>
                                        <div className=''>

                                            <Link
                                                to={`/category/${sub.slug}?id=${spcificCategory._id}&banner=${sub.banner ? sub.banner : ''}`}
                                            >
                                                <img src={sub.img} width='70px' height='70px' className='h-[70px] mx-auto' alt="category images" />
                                                <h2 title={sub.name} className='mt-2 text-center cursor-pointer text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2>
                                            </Link>

                                        </div>
                                    </div>)
                                }
                            </div>
                            <div className="card-actions mt-10">
                                <Link className='cursor-pointer text-blue-500' to={`/more-category/${spcificCategory._id}`}>See more</Link>
                                {/* <p onClick={() => handelCategory(spcificCategory._id)} className='cursor-pointer text-blue-500'>See more</p> */}
                            </div>
                        </div>
                    }

                </div>

                {
                    MenSpcificCategory && <div className="card  bg-base-100 shadow-lg hover:shadow-2xl">
                        <div className="card-body">
                            <h2 className="card-title text-lg font-bold">Shop Men's Collections</h2>
                            <div className='grid grid-cols-2 gap-4 mt-4'>
                                {
                                    MenSpcificCategory?.category.slice(0, 4).map((sub, index) => sub.status && <div className='flex justify-center gap-4' key={index}>
                                        <div>

                                            <Link
                                                to={`/category/${sub.slug}?id=${MenSpcificCategory._id}&banner=${sub.banner ? sub.banner : ''}`}
                                            >
                                                <img src={sub.img} width='70px' height='70px' className='h-[70px] mx-auto' alt="" />
                                                <h2 title={sub.name} className='mt-2 text-center cursor-pointer text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2>
                                            </Link>
                                            {/* <h2 title={sub.name} onClick={() => navigate(`/category/${sub.slug}`)} className='mt-2 cursor-pointer  text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2> */}
                                        </div>
                                    </div>)
                                }

                            </div>
                            <div className="card-actions mt-10">
                                <Link className='cursor-pointer text-blue-500' to={`/more-category/${MenSpcificCategory._id}`}>See more</Link>
                                {/* <p onClick={() => handelCategory(MenSpcificCategory?._id)} className='cursor-pointer text-blue-500'>See more</p> */}
                            </div>
                        </div>
                    </div>
                }


                {
                    HomepcificCategory && <div className="card  bg-base-100 shadow-lg hover:shadow-2xl">
                        <div className="card-body">
                            <h2 className="card-title text-lg font-bold">Shop for {HomepcificCategory?.name}</h2>
                            <div className='grid grid-cols-2 gap-4 mt-4'>
                                {
                                    HomepcificCategory?.category.slice(0, 4).map((sub, index) => <div className='flex justify-center gap-4' key={index}>
                                        <div>

                                            <Link
                                                to={`/category/${sub.slug}?id=${HomepcificCategory._id}&banner=${sub.banner ? sub.banner : ''}`}
                                            >
                                                <img src={sub.img} width='70px' height='70px' className='h-[70px] mx-auto' alt="" />
                                                <h2 title={sub.name} className='mt-2 text-center cursor-pointer text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2>
                                            </Link>
                                            {/* <h2 title={sub.name} onClick={() => navigate(`/category/${sub.slug}`)} className='mt-2 cursor-pointer  text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2> */}
                                        </div>
                                    </div>)
                                }

                            </div>
                            <div className="card-actions mt-10">
                                <Link className='cursor-pointer text-blue-500' to={`/more-category/${HomepcificCategory._id}`}>See more</Link>
                                {/* <p onClick={() => handelCategory(HomepcificCategory?._id)} className='cursor-pointer text-blue-500'>See more</p> */}
                            </div>
                        </div>
                    </div>
                }




                <div className=" bg-base-100 shadow-lg rounded-2xl hover:shadow-2xl">

                    <h2 className="card-title text-lg font-bold ml-6 px-2 py-6 mt-2 text-center">Pre-order Collections</h2>

                    <div className='w-full p-2'>
                        <img className='w-full' src={preOrder} alt="pre order images" />
                    </div>
                    <div className="card-actions mx-4 pb-6 mt-16">
                        <Link to={`/pre-order`} className='cursor-pointer btn text-blue-500'>Shop Now</Link>
                    </div>
                </div>


            </div>


            <div className='grid grid-cols-1 px-4 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-6 '>
                {
                    PetSpcificCategory && <div className="card  bg-base-100 shadow-lg hover:shadow-2xl">
                        <div className="card-body">
                            <h2 className="card-title text-lg font-bold">Shop for your Pets</h2>
                            <div className='grid grid-cols-2 gap-4 mt-4'>
                                {
                                    PetSpcificCategory?.category.slice(0, 4).map((sub, index) => sub.status && <div className='flex justify-center gap-4' key={index}>
                                        <div>
                                            <Link
                                                to={`/category/${sub.slug}?id=${PetSpcificCategory._id}&banner=${sub.banner ? sub.banner : ''}`}
                                            >
                                                <img src={sub.img} width='70px' height='70px' className='h-[70px] mx-auto' alt="" />
                                                <h2 title={sub.name} className='mt-2 text-center cursor-pointer text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2>
                                            </Link>
                                            {/* <h2 title={sub.name} onClick={() => navigate(`/category/${sub.slug}`)} className='mt-2 cursor-pointer  text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2> */}
                                        </div>
                                    </div>)
                                }

                            </div>
                            <div className="card-actions mt-10">
                                <Link className='cursor-pointer text-blue-500' to={`/more-category/${PetSpcificCategory._id}`}>See more</Link>
                                {/* <p onClick={() => handelCategory(PetSpcificCategory?._id)} className='cursor-pointer text-blue-500'>See more</p> */}
                            </div>
                        </div>
                    </div>
                }
                {
                    GadgetsSpcificCategory && <div className="card  bg-base-100 shadow-lg hover:shadow-2xl">
                        <div className="card-body">
                            <h2 className="card-title text-lg font-bold">Shop for Gadgets</h2>
                            <div className='grid grid-cols-2 gap-4 mt-4'>
                                {
                                    GadgetsSpcificCategory?.category.slice(0, 4).map((sub, index) => sub.status && <div className='flex justify-center gap-4' key={index}>
                                        <div>
                                            <Link
                                                to={`/category/${sub.slug}?id=${GadgetsSpcificCategory._id}&banner=${sub.banner ? sub.banner : ''}`}
                                            >
                                                <img src={sub.img} width='70px' height='70px' className='h-[70px] mx-auto' alt="" />
                                                <h2 title={sub.name} className='mt-2 text-center cursor-pointer text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2>
                                            </Link>
                                            {/* <h2 title={sub.name} onClick={() => navigate(`/category/${sub.slug}`)} className='mt-2 cursor-pointer  text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2> */}
                                        </div>
                                    </div>)
                                }

                            </div>
                            <div className="card-actions mt-10">
                                <Link className='cursor-pointer text-blue-500' to={`/more-category/${GadgetsSpcificCategory._id}`}>See more</Link>
                                {/* <p onClick={() => handelCategory(GadgetsSpcificCategory?._id)} className='cursor-pointer text-blue-500'>See more</p> */}
                            </div>
                        </div>
                    </div>
                }
                {
                    HomeSpcificCategory && <div className="card  bg-base-100 shadow-lg hover:shadow-2xl">
                        <div className="card-body">
                            <h2 className="card-title text-lg font-bold">Shop Gourment Items</h2>
                            <div className='grid grid-cols-2 gap-4 mt-4'>
                                {
                                    HomeSpcificCategory?.category.slice(0, 4).map((sub, index) => sub.status && <div className='flex justify-center gap-4' key={index}>
                                        <div>
                                            <Link
                                                to={`/category/${sub.slug}?id=${HomeSpcificCategory._id}&banner=${sub.banner ? sub.banner : ''}`}
                                            >
                                                <img src={sub.img} width='70px' height='70px' className='h-[70px] mx-auto' alt="" />
                                                <h2 title={sub.name} className='mt-2 text-center cursor-pointer text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2>
                                            </Link>
                                            {/* <h2 title={sub.name} onClick={() => navigate(`/category/${sub.slug}`)} className='mt-2 cursor-pointer  text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2> */}
                                        </div>
                                    </div>)
                                }

                            </div>
                            <div className="card-actions mt-10">
                                <Link className='cursor-pointer text-blue-500' to={`/more-category/${HomeSpcificCategory._id}`}>See more</Link>
                                {/* <p onClick={() => handelCategory(HomeSpcificCategory?._id)} className='cursor-pointer text-blue-500'>See more</p> */}
                            </div>
                        </div>
                    </div>
                }
                {
                    FestivalSpcificCategory && <div className="card  bg-base-100 shadow-lg hover:shadow-2xl">
                        <div className="card-body">
                            <h2 className="card-title text-lg font-bold">Shop Express Delivery offers</h2>
                            <div className='grid grid-cols-2 gap-4 mt-4'>
                                {
                                    FestivalSpcificCategory?.category.slice(0, 4).map((sub, index) => sub.status && <div className='flex justify-center gap-4' key={index}>
                                        <div>
                                            <Link
                                                to={`/category/${sub.slug}?id=${FestivalSpcificCategory._id}&banner=${sub.banner ? sub.banner : ''}`}
                                            >
                                                <img src={sub.img} width='70px' height='70px' className='h-[70px] mx-auto' alt="" />
                                                <h2 title={sub.name} className='mt-2 text-center cursor-pointer text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2>
                                            </Link>
                                            {/* <h2 title={sub.name} onClick={() => navigate(`/category/${sub.slug}`)} className='mt-2 cursor-pointer  text-orange-500 hover:underline hover:underline-offset-2 font-semibold text-[12px]'>{sub.name}</h2> */}
                                        </div>
                                    </div>)
                                }

                            </div>
                            <div className="card-actions mt-10">
                                <Link className='cursor-pointer text-blue-500' to={`/more-category/${FestivalSpcificCategory._id}`}>See more</Link>
                                {/* <p onClick={() => handelCategory(FestivalSpcificCategory?._id)} className='cursor-pointer text-blue-500'>See more</p> */}
                            </div>
                        </div>
                    </div>
                }
            </div>


        </div>
    );
};

export default ShopCategory;