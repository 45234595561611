import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useParams } from 'react-router-dom';
import { useMedia } from 'react-use-media';
import { cartContext } from '../../App';
import Spinner from '../../Shared/Spinner/Spinner';
import { addToCartLocal } from '../../Utilitis/addToCartLocal';
import MobileProductCart from '../Cart/MobileProductCart';
import ProductCart from '../Cart/ProductCart';

const SearchAll = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const { searchText } = useParams();
  const [searchProduct, setSearchProduct] = useState([]);
  const [searchLoad, setSearchLoad] = useState(false);


  const extraSmall = useMedia("(max-width : 450px)");
  const isMobile = useMedia("(max-width: 700px)");
  const tab = useMedia("(min-width: 700px) and (max-width: 1000px)");
  const largePc = useMedia("(min-width: 1000px) and (min-width: 1800px)");


  const carts = useContext(cartContext)
  useEffect(() => {
    setSearchLoad(true)
    fetch(`https://api.shopinshop.com.bd/product/search/public?name=${searchText}`)
      .then(res => res.json())
      .then(result => {
        setSearchProduct(result);
        setSearchLoad(false)
      })
  }, [searchText]);

  const [cart, setCartValue, setCart] = carts;
  setCartValue(searchProduct);


  if (searchLoad) {
    return <Spinner></Spinner>
  }
  if (searchProduct.length <= 0) {
    return <div className='h-screen flex items-center justify-center'>
      <h2 className='text-orange-500 text-xl'>Sorry! Your search product could not be found</h2>
    </div>
  }

  const handelCart = (product) => {

    let newCart = [];
    const exists = cart.find(cartProduct => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newCart = [...cart, product];
    }
    else {
      const rest = cart.filter(cartProduct => cartProduct._id !== product._id);
      product.quantity += 1;
      newCart = [...rest, exists];
    }

    if (!searchLoad) {
      setCart(newCart);
      addToCartLocal(product._id);
    }

  }


  const handelSingleProduct = (id) => {
    Navigate(`/singleProduct/${id}`);
  };



  // console.log(searchProduct)
  return (
    <div
      className={`gap-5 mb-10 grid px-5 justify-center ${isMobile
        ? "grid-cols-2"
        : largePc
          ? "grid-cols-6"
          : tab
            ? "grid-cols-3"
            : "grid-cols-4"
        }`}
    >
      <Helmet>
        <title>Shop In Shop | All Product</title>
      </Helmet>
      {searchProduct?.map((product) =>
        extraSmall ? (
          <MobileProductCart
            product={product}
            handelWhishList={() => false}
            handelSingleProduct={handelSingleProduct}
            handelCart={handelCart}
            isMobile={isMobile}
          ></MobileProductCart>
        ) : (
          <ProductCart
            product={product}
            handelWhishList={() => false}
            handelSingleProduct={handelSingleProduct}
            handelCart={handelCart}
            isMobile={isMobile}
          ></ProductCart>
        )
      )}
    </div>
  );
};

export default SearchAll;