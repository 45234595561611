import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../Shared/Spinner/Spinner';

const AllCategory = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const { id } = useParams();
    const navigate = useNavigate();
    const { isLoading, data } = useQuery(['singleCategory', id], () =>
        fetch(`https://api.shopinshop.com.bd/category/all/public/client/${id}`)
            .then(res => res.json())
    );
    if (isLoading) {
        return <Spinner></Spinner>
    }
    // console.log(data)

    return (
        <div className='px-12 my-10'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                {
                    data?.category.map(category => <div key={category.slug} className="card w-96 bg-base-100 shadow-xl">
                        <figure className="px-10 pt-10">
                            <img className="h-28 w-28 rounded-xl" src={`${category.img}`} alt="category name and Images" />
                        </figure>
                        <div className="card-body items-center text-center">
                            <h2 onClick={() =>
                                // navigate(`/category/${category.slug}`)}
                                navigate(`/category/${category.slug}?id=${data._id}&banner=${category.banner ? category.banner : ''}`)}
                                className="card-titl font-bold hover:text-primary cursor-pointer">{category.name}</h2>
                            <div className='mt-6'>
                                <div className='grid grid-cols-3 gap-4'>
                                    {
                                        category?.subCategory.map((sub, index) => <div key={index} className="tooltip tooltip-primary" data-tip={sub.name}>
                                            <p onClick={() => navigate(`/sub-category/${sub.slug}`)} className="text-sm hover:bg-slate-100 hover:underline cursor-pointer rounded-lg text-sm text-orange-500">{sub.name}</p>
                                        </div>)
                                    }

                                </div>
                            </div>

                        </div>
                    </div>)
                }
            </div>
        </div>
    );
};

export default AllCategory;