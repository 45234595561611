import Carousel from "nuka-carousel";
import React from "react";
import { useQuery } from "react-query";
import Spinner from "../../Shared/Spinner/Spinner";

const Slider = () => {

  const { isLoading, data } = useQuery('slider', () =>
    fetch('https://api.shopinshop.com.bd/slider/client/public')
      .then(res => res.json())
  );



  if (isLoading) {
    return <Spinner></Spinner>
  }

  return (
    <div className="z-[-1]">
      <Carousel
        autoplay={true}
        wrapAround={true}
        pauseOnHover={true}
        autoplayInterval={4000}
        dragging={true}
        animation="fade"
        renderCenterLeftControls={({ previousSlide }) => (
          <button className="h-10 ml-6 w-10 hover:bg-[#F97316] rounded-full flex justify-center items-center" onClick={previousSlide}>
            {/* <i className="text-2xl fa-solid fa-angle-left"></i> */}
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button className="h-10 mr-6 w-10 hover:bg-[#F97316] rounded-full flex justify-center items-center" onClick={nextSlide}>
            {/* <i className="text-2xl fa-solid fa-angle-right"></i> */}
          </button>
        )}
        defaultControlsConfig={{
          nextButtonText: "Custom Next",
          prevButtonText: "Custom Prev",
          pagingDotsStyle: {
            fill: "red",
            margin: "0 5px",
          },
        }}
      >
        {
          data?.map(slider => slider.status &&

            <div className="relative" key={slider._id}>
              {
                slider.textBox &&
                <div className="absolute md:block hidden bottom-[50%] top-[20%] left-[200px] w-[35%]">

                  <h2 className="font-bold text-primary text-4xl mb-2 italic">{slider.title}</h2>
                  <p className="font-semibold">{slider.description}</p>
                  <a href={slider.link} rel="noopener noreferrer" target='_blank' className="btn bg-[#F97316] mt-4 text-primary hover:text-white">Shop Now</a>
                </div>
              }

              <a href={slider.link} rel="noopener noreferrer" target='_blank'> <img className="w-full" src={`https://api.shopinshop.com.bd/${slider.img}`} alt="slider" /></a>
            </div>
          ).reverse()
        }
      </Carousel>
    </div>
  );
};

export default Slider;
