import React from "react";
import { Link, useNavigate } from "react-router-dom";

const MobileMenuItem = ({ menuItem, setMobileMenu, search }) => {
  // console.log(menuItem)
  const navigate = useNavigate();
  return (
    <div className="">
      {!search &&
        <div
          tabIndex="0"
          className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
        >
          <input type="checkbox" className="peer" />
          <div className="collapse-title text-lg font-semibold">
            {menuItem.name}
          </div>
          <div className="collapse-content text-primary">
            <div className="grid grid-cols-2 gap-4">
              {menuItem.category.map(
                (subItem, index) =>
                  subItem.status && (
                    <div key={index}>
                      <h2
                        onClick={() => {
                          setMobileMenu(false);
                          // navigate(`/category/${subItem.slug}`);
                          navigate(`/category/${subItem.slug}?id=${menuItem._id}&banner=${subItem.banner ? subItem.banner : ''}`);
                        }}
                        title={subItem.name}
                        className="font-bold cursor-pointer hover:text-orange-500"
                      >
                        {subItem.name.length > 15
                          ? subItem.name.slice(0, 15) + ".."
                          : subItem.name}
                      </h2>
                      {subItem.subCategory.map((sub, index) => (
                        <Link
                          // to={`/sub-category/${sub.slug}`}
                          to={`/sub-category/${sub.slug}?id=${menuItem._id}&banner=${sub.banner ? sub.banner : ''}`}
                          key={index}
                          onClick={() => setMobileMenu(false)}
                          className="text-black cursor-pointer hover:bg-slate-200 block py-2 px-4 rounded-full text-justify text-sm"
                        >
                          {sub.name}
                        </Link>
                      ))}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default MobileMenuItem;
