import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useMedia } from 'react-use-media';
import { cartContext } from '../../App';
import Spinner from '../../Shared/Spinner/Spinner';
import { addToWishLocal } from '../../Utilitis/AddtoWishList';
import { addToCartLocal } from '../../Utilitis/addToCartLocal';
import MobileProductCart from '../Cart/MobileProductCart';
import ProductCart from '../Cart/ProductCart';
import Pagination from '../Pagination/Pagination';

const SubCategoryProduct = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const { slug } = useParams();

  // const [data, isLoading, setCurrentPage, currentPage] = useProducts();
  const extraSmall = useMedia("(max-width : 450px)");
  const isMobile = useMedia("(max-width: 700px)");
  const tab = useMedia("(min-width: 700px) and (max-width: 1000px)");
  const largePc = useMedia("(min-width: 1000px) and (min-width: 1800px)");
  const url = window?.location?.search;
  const banner = url?.match(/banner=([^&]*)/)[1] || '';

  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, data } = useQuery(['subCategoryProducts', currentPage, slug], () =>
    fetch(`https://api.shopinshop.com.bd/subCategory/product/client/public/${slug}?page=${currentPage - 1}`)
      .then(res => res.json())
  );

  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [isLoadings, setIsloading] = useState(false);


  // const isSubCategory = data?.filter(cat => cat.subCategory?.some(x => x.slug === slug) && cat);


  useEffect(() => {
    setIsloading(true);
    fetch(`https://api.shopinshop.com.bd/product/client/public/subCategory/count/${slug}`)
      .then(res => res.json())
      .then(result => {
        const count = result.count;
        const pages = Math.ceil(parseInt(count) / 20);
        setPageCount(pages);
        setTotalItem(count);
        setIsloading(false);
      })
  }, [slug]);
  const carts = useContext(cartContext);
  const [cart, setCartValue, setCart, whishList, setWhishList] = carts;
  setCartValue(data);
  if (isLoading || isLoadings) {
    return <Spinner></Spinner>
  };

  if (!isLoading && data.length <= 0) {
    return <div className='h-screen flex items-center justify-center'>
      <h2 className='text-orange-500 text-2xl'>Sorry! No products found in this category!!</h2>
    </div>
  };

  const handelSingleProduct = id => {
    navigate(`/singleProduct/${id}`)
  };

  const handelWhishList = (product) => {
    let newWishList = [];
    const exists = whishList.find(cartProduct => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newWishList = [...whishList, product];
    }
    else {
      const rest = whishList.filter(cartProduct => cartProduct._id !== product._id);
      product.quantity += 1;
      newWishList = [...rest, exists];
    }

    if (!isLoading) {
      setWhishList(newWishList);
      addToWishLocal(product._id);
    }

  };



  const handelCart = (product) => {
    let newCart = [];
    const exists = cart.find(cartProduct => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newCart = [...cart, product];
    }
    else {
      const rest = cart.filter(cartProduct => cartProduct._id !== product._id);
      product.quantity += 1;
      newCart = [...rest, exists];
    }

    if (!isLoading) {
      setCart(newCart);
      addToCartLocal(product._id);
    }

  }


  return (
    <div>
      <Helmet>
        <title>Shop In Shop | {slug}</title>
      </Helmet>

      {banner !== "undefined" && (
        <div className={banner ? "w-full mb-6" : "w-full mb-6 hidden"}>
          <img className="w-full" src={banner} alt="banner images"></img>
        </div>
      )}
      <div
        className={`gap-5 mb-10 grid px-5 justify-center ${isMobile
          ? "grid-cols-2"
          : largePc
            ? "grid-cols-6"
            : tab
              ? "grid-cols-3"
              : "grid-cols-4"
          }`}
      >
        {data &&
          data?.map((product, index) =>
            extraSmall ? (
              <MobileProductCart
                key={index}
                product={product}
                handelWhishList={handelWhishList}
                handelSingleProduct={handelSingleProduct}
                handelCart={handelCart}
                isMobile={isMobile}
              ></MobileProductCart>
            ) : (
              <ProductCart
                key={index}
                product={product}
                handelWhishList={handelWhishList}
                handelSingleProduct={handelSingleProduct}
                handelCart={handelCart}
                isMobile={isMobile}
              ></ProductCart>
            )
          )}
      </div>

      {totalItem > 20 && (
        <div className="flex justify-center">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
          ></Pagination>
        </div>
      )}
    </div>
  );
};

export default SubCategoryProduct;