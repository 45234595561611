import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { cartContext } from '../../App';
import Spinner from '../../Shared/Spinner/Spinner';
import { addToWishLocal } from '../../Utilitis/AddtoWishList';
import { addToCartLocal } from '../../Utilitis/addToCartLocal';
import Pagination from '../Pagination/Pagination';

const PreOrderProduct = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [isLoadings, setIsloading] = useState(false);
  const navigate = useNavigate();
  const { isLoading, data, refetch } = useQuery(['products-pre', currentPage], () =>
    fetch(`https://api.shopinshop.com.bd/allproduct/client/public/pre-order?page=${currentPage - 1}`)
      .then(res => res.json())
  );


  useEffect(() => {
    setIsloading(true);
    fetch(`https://api.shopinshop.com.bd/product/client/public/count/pre-order`)
      .then(res => res.json())
      .then(result => {
        const count = result.count;
        const pages = Math.ceil(parseInt(count) / 10);
        setPageCount(pages);
        setTotalItem(count);
        setIsloading(false);
      })
  }, []);
  const carts = useContext(cartContext);
  const [cart, setCartValue, setCart, whishList, setWhishList] = carts;
  setCartValue(data);

  if (isLoading || isLoadings) {
    return <Spinner></Spinner>
  };




  const handelSingleProduct = id => {
    navigate(`/singleProduct/${id}`)
  }
  const handelCart = (product) => {
    let newCart = [];
    const exists = cart.find(cartProduct => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newCart = [...cart, product];
    }
    else {
      const rest = cart.filter(cartProduct => cartProduct._id !== product._id);
      product.quantity += 1;
      newCart = [...rest, exists];
    }

    if (!isLoading) {
      setCart(newCart);
      addToCartLocal(product._id);
      refetch();
    }

  };


  const handelWhishList = (product) => {
    let newWishList = [];
    const exists = whishList.find(cartProduct => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newWishList = [...whishList, product];
    }
    else {
      const rest = whishList.filter(cartProduct => cartProduct._id !== product._id);
      product.quantity += 1;
      newWishList = [...rest, exists];
    }

    if (!isLoading) {
      setWhishList(newWishList);
      addToWishLocal(product._id);
    }

  };


  return (
    <div>
      <Helmet>
        <title>Shop In Shop | Pre-Order</title>
      </Helmet>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 container mx-auto my-10">
        {data?.map((product) => (
          <div
            key={product._id}
            className="card mx-auto card-compact w-72  bg-base-100 shadow-xl"
          >
            <figure className="relative ">
              <div className="h-[280px] w-[280px]">
                <img
                  onClick={() => handelSingleProduct(product._id)}
                  className="transition ease-in-out delay-150 cursor-pointer  hover:-translate-y-1 hover:scale-110 duration-300"
                  src={`https://api.shopinshop.com.bd/${product.primaryImage}`}
                  alt="Shoes"
                  width="280"
                  height="280"
                />
              </div>

              {product.sPrice && (
                <p className="absolute top-2 left-2 text-white text-sm bg-[#2BA968] rounded-full p-1">
                  -
                  {parseFloat(
                    (100 * (product.price - product.sPrice)) / product.price
                  ).toFixed(2)}
                  %
                </p>
              )}
            </figure>
            <div className="card-body">
              <h2
                title={product.productName}
                onClick={() => handelSingleProduct(product._id)}
                className="hover:text-orange-500 card-title cursor-pointer text-[15px] mx-auto"
              >
                {" "}
                {product?.productName.length > 30
                  ? product?.productName.slice(0, 30) + "...."
                  : product.productName}
              </h2>
              <div className="rating rating-xs mx-auto">
                <StarRatings
                  rating={product.avgRating || 0}
                  starRatedColor="orange"
                  starDimension="20px"
                  starSpacing="1px"
                />
              </div>

              {product?.sPrice ? (
                <div className="text-center">
                  <p>
                    {" "}
                    <span className="line-through">
                      {product.price} &#2547;
                    </span>{" "}
                    <span className="text-lg font-bold">
                      {product.sPrice} &#2547;
                    </span>{" "}
                  </p>
                </div>
              ) : (
                <div className="text-center">
                  <p>
                    <span className="text-lg font-bold">
                      {product.price} &#2547;
                    </span>{" "}
                  </p>
                </div>
              )}

              <div className="card-actions justify-evenly items-center mt-2">
                <i
                  onClick={() => handelWhishList(product)}
                  className="hover:-translate-y-1 hover:scale-110 duration-300 transition ease-in-out delay-150  text-orange-500 cursor-pointer far fa-heart text-xl "
                ></i>
                {/* <i className="fas fa-heart"></i> */}
                <button
                  onClick={() => handelSingleProduct(product._id)}
                  className="btn btn-primary  btn-sm text-sm"
                >
                  Buy Now
                </button>
                <i
                  onClick={() => {
                    handelCart(product);
                  }}
                  className="text-xl  hover:-translate-y-1 hover:scale-110 duration-300 transition ease-in-out delay-150  text-orange-500 cursor-pointer fas fa-cart-arrow-down"
                ></i>
              </div>
            </div>
          </div>
        ))}
      </div>

      {totalItem > 20 && (
        <div className="flex justify-center">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
          ></Pagination>
        </div>
      )}
    </div>
  );
};

export default PreOrderProduct;