import React from "react";
import { useNavigate } from "react-router-dom";
import MobileMenuItem from "./MobileMenuItem";

const MobileMenu = ({
  data,
  setSearch,
  search,
  searchProduct,
  setMobileMenu,
}) => {
  // const [closeModal, setCloseModal] = useState(false);
  const navigation = useNavigate();
  const searchHandel = (event) => {
    event.preventDefault();
    const search = event.target.search.value.toLowerCase();
    navigation(`/search/${search}`);
    setSearch("");
    event.target.reset();
  };

  // Function to handle search input change
  // function handleSearchInputChange(event) {
  //   event.preventDefault();
  //   // setSearchQuery(event.target.value);
  //   // setSearch(e.target.value.toLowerCase())
  //   setCloseModal(event.target.value !== ''); // Only show modal if search query is not empty
  // }


  return (
    <div className="md:hidden">
      <div className="shadow-lg p-4 z-[-1]  bg-white w-96 rounded-lg">
        <div className="form-control relative">
          <form onSubmit={searchHandel}>
            <div className="input-group">
              <input
                name="search"
                onChange={(e) => {
                  setSearch(e.target.value.toLowerCase())
                  handleSearchInputChange()
                }}
                type="text"
                placeholder="Search…"
                className="input input-bordered w-full"
                required
              />
              <button
                className="btn btn-square"
                onClick={() => setMobileMenu(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
              {search && (
                <div className="bg-white overflow-scroll z-[99999999999999999999999999] rounded-lg absolute top-12  w-full h-96">
                  <div className="p-4">
                    {searchProduct.data !== false &&
                      searchProduct?.map((product) => (
                        <div
                          key={product._id}
                          onClick={() => {
                            setMobileMenu(false);
                            navigation(`/singleProduct/${product._id}`);
                            setSearch("");
                          }}
                          className="flex justify-between items-center py-2 px-4 cursor-pointer rounded-full  hover:bg-stone-100"
                        >
                          <img
                            src={`https://api.shopinshop.com.bd/${product.primaryImage}`}
                            className="w-10 rounded-full bg-white"
                            alt="product images"
                            onClick={() => setMobileMenu(false)}
                          />
                          <p className="hover:text-orange-500 cursor-pointer">
                            {product.productName.slice(0, 18)}
                          </p>

                          {product.sPrice && <p>{product.sPrice} &#2547;</p>}

                          {product.sPrice ? (
                            <p>
                              <del>{product.price} &#2547;</del>
                            </p>
                          ) : (
                            <p>{product.price} &#2547;</p>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="mt-10">
          {data.map((menuItem) => (
            <MobileMenuItem
              key={menuItem._id}
              menuItem={menuItem}
              setMobileMenu={setMobileMenu}
              // closeModal={closeModal}
              search={search}
            ></MobileMenuItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
