import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useCustomerInfo from '../../Hooks/useCustomerInfo';
import ButtonSpinner from '../../Shared/ButtonSpinner';
import Spinner from '../../Shared/Spinner/Spinner';
import auth from '../../firebase.init';
const Address = () => {

    const [user, loading] = useAuthState(auth);
    const [customerInfo] = useCustomerInfo(user?.email);
    const [state, setState] = useState([]);
    const [singleSate, setSingleState] = useState('');
    const [singleCity, setSingleCity] = useState('');
    const [allAddress, setAllAddress] = useState('')
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        fetch('/fakeCity.json')
            .then(res => res.json())
            .then(stateData => setState(stateData));
    }, [singleSate]);
    const city = state?.find(city => city.state === singleSate);

    const handelAddress = event => {
        event.preventDefault();
        const inputSate = event.target.inputState.value || singleSate;
        const inputCity = event.target.inputCity.value || singleCity;
        const allAddres = event.target.detail.value || allAddress;
        const address = { state: inputSate, city: inputCity, detail: allAddres }

        setSpinner(true);
        fetch(`https://api.shopinshop.com.bd/customer/info/update/${user?.email}`, {
            method: "PUT",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify(address)
        })
            .then(res => res.json())
            .then(data => {
                if (data.acknowledged) {
                    toast('Your address has been saved');
                    navigate('/profile');
                    setSpinner(false);
                }
            })


    }

    if (loading) {
        return <Spinner></Spinner>
    }
    return (
        <div className='px-12 md:px-0 container mx-auto h-screen mt-10'>
            <div className='md:w-6/12 w-full mx-auto'>
                <form onSubmit={handelAddress}>
                    <div className='flex justify-between gap-4'>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">State</span>
                            </label>
                            <select defaultValue={customerInfo?.address?.state} onChange={(e) => setSingleState(e.target.value)} name="inputState" className="select select-bordered"
                                required
                            >
                                <option disabled selected >{customerInfo?.address?.state || 'Pick One'}</option>
                                {
                                    state.map(sates => <option key={sates._id}>{sates.state}</option>)
                                }
                            </select>

                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">City</span>
                            </label>
                            <select defaultValue={customerInfo?.address?.city} onChange={(e) => setSingleCity(e.target.value)} name="inputCity" className="select select-bordered"
                                required
                            >
                                <option disabled selected>{customerInfo?.address?.city || 'Pick one'}</option>
                                {
                                    city?.city.map((city, index) => <option key={index}>{city}</option>
                                    )
                                }
                            </select>

                        </div>
                    </div>

                    <div className="form-control">
                        <label className="label">
                            <span className="label-text capitalize">Your detail Address</span>
                        </label>
                        <textarea onChange={(e) => setAllAddress(e.target.value)} defaultValue={customerInfo?.address?.detail || ''} className="textarea textarea-bordered h-24 capitalize" name='detail' placeholder="Your detail Address"></textarea>
                    </div>

                    <div className='mt-4 flex justify-end'>
                        {
                            spinner ? <ButtonSpinner></ButtonSpinner> : <button className='btn btn-primary'>Save Address</button>
                        }


                    </div>
                </form>
            </div>
        </div>
    );
};

export default Address;