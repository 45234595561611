import { createContext, useState } from "react";
import {Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import { useCart } from "./Hooks/useCart";
import { useWishLish } from "./Hooks/useWishList";
import Blog from "./Pages/Blog/Blog";
import AllCategory from "./Pages/CategoryProduct/AllCategory";
import CategoryProduct from "./Pages/CategoryProduct/CategoryProduct";
import MainCategoryProduct from "./Pages/CategoryProduct/MainCategoryProduct";
import PreOrderProduct from "./Pages/CategoryProduct/PreOrderProduct";
import SubCategoryProduct from "./Pages/CategoryProduct/SubCategoryProduct";
import CheckOut from "./Pages/CheckOut/CheckOut";
import Facebook from "./Pages/Facebook/Facebook";
import Feedback from "./Pages/Feedback/Feedback";
import Cancel from "./Pages/Footer/Cancel";
import Career from "./Pages/Footer/Career";
import Footer from "./Pages/Footer/Footer";
import ReturnRefundPolicy from "./Pages/Footer/ReturnRefundPolicy";
import Shipping from "./Pages/Footer/Shipping";
import Terms from "./Pages/Footer/Terms";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import ResetPassword from "./Pages/Login/ResetPassword";
import CodOrderSuccess from "./Pages/OrderInfo/CodOrderSuccess";
import OrderCencel from "./Pages/OrderInfo/OrderCencel";
import OrderFaild from "./Pages/OrderInfo/OrderFaild";
import OrderSuccess from "./Pages/OrderInfo/OrderSuccess";
import About from "./Pages/PrivacyPolicy/About";
import Cookies from "./Pages/PrivacyPolicy/Cookies";
import Faqs from "./Pages/PrivacyPolicy/Faqs";
import Privacy from "./Pages/PrivacyPolicy/Privacy";
import AllProducts from "./Pages/Products/AllProducts";
import Address from "./Pages/Profile/Address";
import MainProfile from "./Pages/Profile/MainProfile";
import Orders from "./Pages/Profile/Orders";
import Profile from "./Pages/Profile/Profile";
import TrackOrder from "./Pages/Profile/TrackOrder";
import Registration from "./Pages/Registration/Registration";
import SearchAll from "./Pages/SearchAll/SearchAll";
import SingleProduct from "./Pages/SingleProduct/SingleProduct";
import ViewCart from "./Pages/ViewCart/ViewCart";
import WishList from "./Pages/WishList/WishList";
import RequireAuth from "./RequireAuth/RequireAuth";
import Header from "./Shared/Header/Header";
import NotFound from "./Shared/NotFound/NotFound";


export const cartContext = createContext()
function App() {
  const [cartValue, setCartValue] = useState([]);
  const [cart, setCart] = useCart(cartValue);
  const [whishList, setWhishList] = useWishLish(cartValue)
  return (
    <cartContext.Provider value={[cart, setCartValue, setCart, whishList, setWhishList]}>
      <div>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/wishList" element={<RequireAuth><WishList></WishList></RequireAuth>}></Route>
          <Route path="/profile" element={<RequireAuth> <Profile></Profile></RequireAuth>}>
            <Route index element={<RequireAuth><MainProfile></MainProfile></RequireAuth>} />
            <Route path="order" element={<RequireAuth><Orders></Orders></RequireAuth>} />
            <Route path="address" element={<RequireAuth><Address></Address></RequireAuth>} />
          </Route>
          <Route path="/category/:slug" element={<CategoryProduct></CategoryProduct>}></Route>
          <Route path="/main-category/:slug" element={<MainCategoryProduct></MainCategoryProduct>}></Route>
          <Route path="/sub-category/:slug" element={<SubCategoryProduct></SubCategoryProduct>}></Route>
          <Route path="/pre-order" element={<PreOrderProduct></PreOrderProduct>}></Route>
          <Route path="/login" element={<Login></Login>}></Route>
          <Route path="/resetpassword" element={<ResetPassword></ResetPassword>} ></Route>
          <Route path="/registration" element={<Registration></Registration>} ></Route>
          <Route path="/singleProduct/:productId" element={<SingleProduct></SingleProduct>} ></Route>
          <Route path='/orderSuccess/:tranId' element={<RequireAuth><OrderSuccess></OrderSuccess></RequireAuth>}></Route>
          <Route path='/COD-orderSuccess/:OrderID' element={<RequireAuth><CodOrderSuccess></CodOrderSuccess></RequireAuth>}></Route>
          <Route path='/orderFail/:tranId' element={<RequireAuth><OrderFaild></OrderFaild></RequireAuth>}></Route>
          <Route path='/orderCencel/:tranId' element={<RequireAuth><OrderCencel></OrderCencel></RequireAuth>}></Route>
          <Route path="/checkOut/:sulg" element={<RequireAuth><CheckOut></CheckOut></RequireAuth>}></Route>
          <Route path="/search/:searchText" element={<SearchAll></SearchAll>}></Route>
          <Route path="/allProduct" element={<AllProducts></AllProducts>}></Route>
          <Route path="/more-category/:id" element={<AllCategory></AllCategory>}></Route>
          <Route path="/track-order/:orderId" element={<RequireAuth><TrackOrder></TrackOrder></RequireAuth>}></Route>
          <Route path="/privacy" element={<Privacy></Privacy>}></Route>
          <Route path="/about" element={<About></About>}></Route>
          <Route path="/cookies" element={<Cookies></Cookies>}></Route>
          <Route path="/terms" element={<Terms></Terms>}></Route>
          <Route path="/shipping" element={<Shipping></Shipping>}></Route>
          <Route path="/faqs" element={<Faqs></Faqs>}></Route>
          <Route path="/blog" element={<Blog></Blog>}></Route>
          <Route path="/returnRefund" element={<ReturnRefundPolicy></ReturnRefundPolicy>}></Route>
          <Route path="/cancel" element={<Cancel></Cancel>}></Route>
          <Route path="/career" element={<Career></Career>}></Route>
          <Route path='/viewCart' element={<RequireAuth><ViewCart></ViewCart></RequireAuth>}></Route>
          <Route path="*" element={<NotFound></NotFound>}></Route>
        </Routes>
        <Feedback></Feedback>
        <Footer></Footer>
        <Facebook></Facebook>
        <ToastContainer />
      </div>

    </cartContext.Provider>

  );
}

export default App;
