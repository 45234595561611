import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
// import ProductCart from "../Cart/ProductCart";
// import useProducts from "../../Hooks/useProducts";
import FetureProduct from "../Products/FetureProduct";
import JustForYou from "../Products/JustForYou";
import HeroSection from "./HeroSection";
import ShopCategory from "./ShopCategory";
import Slider from "./Slider";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


  return (
    <div>
      <Helmet>
        <title>Shop In Shop | Home</title>
      </Helmet>
      <Slider></Slider>
      <HeroSection></HeroSection>
      {/* <ProductCart></ProductCart> */}
      <ShopCategory></ShopCategory>
      <div className="divider px-5 md:px-10  mx-auto my-10">
        <span className="font-bold uppercase">Top PRODUCTS</span>{" "}
      </div>
      <FetureProduct></FetureProduct>
      <div className="divider container mx-auto my-10">
        <span className="font-bold">NEW FOR YOU</span>{" "}
      </div>
      <JustForYou></JustForYou>
    </div>
  );
};

export default Home;
