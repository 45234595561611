import { signOut } from "firebase/auth";
import React, { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { cartContext } from "../../App";
import logo from "../../Images/logo/logo.jpeg";
import { deleteItemByID, deleteLocalStore, getShoppingCart } from "../../Utilitis/addToCartLocal";
import auth from "../../firebase.init";
import Cart from "./Cart";


const TopHeader = ({
  toggelCart,
  setToggelCart,
  mobileMenu,
  setMobileMenu,
  toggelProfile,
  setToggelProfile,
  setSearch,
  search,
  searchProduct,
}) => {
  const [user] = useAuthState(auth);
  const carts = useContext(cartContext);
  const cartProduct = Object.keys(getShoppingCart());
  const navigation = useNavigate();
  const { isLoadings, data, refetch } = useQuery(['productCart', cartProduct], () =>
    fetch(`https://api.shopinshop.com.bd/products/cart?name=${cartProduct}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,

      },

    })
      .then(res => res.json())
  );



  // console.log(data)
  const [cart, setCartValue, setCart, whishList] = carts;
  setCartValue(data);
  // const [whishList, setWhishList] = useWishLish();
  // console.log(data)
  const handelDeleteItem = (id) => {
    deleteItemByID(id);
    refetch();
  };
  const handelDeleteShoppingCart = () => {
    deleteLocalStore();
    refetch();
  };


  const searchHandel = (event) => {
    event.preventDefault();
    const search = event.target.search.value.toLowerCase();
    navigation(`/search/${search}`);
    setSearch('');
    event.target.reset();
  }
  // if (isLoading) {
  //   return <Spinner></Spinner>
  // }

  // console.log(searchProduct)

  return (
    <div className="bg-[#1A1B1F] z-[999999999999999999] py-2">
      <div className="px-5 md:px-10  mx-auto  grid grid-cols-3 items-center md:grid-cols-5 lg:grid-cols-6 ">
        <div className="col-span-1 flex justify-around md:flex-none md:justify-start items-center sm:pl-0">
          <div
            onClick={() => setMobileMenu(!mobileMenu)}
            className="block md:hidden"
          >
            <label className="drawer-button">
              {mobileMenu ? (
                <i className="text-white text-xl cursor-pointer fa-solid fa-x"></i>
              ) : (
                <i className="text-white text-xl cursor-pointer fa-solid fa-bars"></i>
              )}
            </label>
          </div>
          <Link to="/">
            {" "}
            <img src={logo} width="60" alt="shop in shop logo" />
          </Link>
        </div>

        <div className="col-span-0 md:col-span-2 relative lg:col-span-3 hidden md:block">
          <form onSubmit={searchHandel}>
            <div className="flex justify-center">
              <input
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
                className="outline-none p-2 rounded-l-lg w-full lg:w-3/4  pl-4"
                type="text"
                name="search"
                placeholder="Search in Shop in Shop"
                required
              />
              <button className="bg-orange-400 rounded-r-lg py-[10px] hover:bg-orange-500 px-4">
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
          </form>
          {search && (
            <div
              style={{ zIndex: "99999999999999999999999" }}
              className="bg-white overflow-y-auto rounded-lg absolute md:w-96 top-12 xl:w-3/4 xl:left-20 w-full h-96"
            >
              <div className="p-6 ">
                {searchProduct.data !== false &&
                  searchProduct?.map((product) => (
                    <>
                      <div
                        data-tip={product.productName}
                        key={product._id}
                        onClick={() => {
                          navigation(`/singleProduct/${product._id}`);
                          setSearch("");
                        }}
                        className="flex justify-between items-center z-[9999999999999999999999999999999999998] tooltip tooltip-primary py-2 px-4 cursor-pointer rounded-full  hover:bg-stone-100"
                      >
                        <img
                          src={`https://api.shopinshop.com.bd/${product.primaryImage}`}
                          className="w-[30px] rounded-full bg-white"
                          alt="product images"
                        />
                        <p className="hover:text-orange-500 text-sm cursor-pointer w-28 text-justify tooltip">
                          {product.productName?.slice(0, 10)}...
                        </p>

                        {product.sPrice && (
                          <p className="w-16 text-justify">
                            {product.sPrice} &#2547;
                          </p>
                        )}

                        {product.sPrice ? (
                          <p className="w-16">
                            <del>{product.price} &#2547;</del>
                          </p>
                        ) : (
                          <p className="w-32">{product.price} &#2547;</p>
                        )}
                        <div className="badge badge-primary w-20 text-[10px]">
                          {product.orderType}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="col-span-2 md:col-span-2 lg:col-span-2 text-white text-center">
          <div className="flex justify-around mx-auto w-full">
            <a
              className="btn btn-sm border-2 border-slate-500 text-white mt-[-3px]"
              href="https://dashboard.shopinshop.com.bd"
            >
              Seller
            </a>

            <div className="relative">
              <i
                onClick={() => {
                  setToggelProfile(!toggelProfile);
                  setToggelCart(false);
                }}
                className=" hover:text-[#DADADA] text-[12px] text-white cursor-pointer fa-regular fa-user"
              >
                &nbsp;&nbsp; {user ? user?.displayName?.split(" ")[0] : "Login"}
              </i>
              <div
                className={`absolute top-[55px] z-[9999999]  rounded-3xl shadow-lg mx-auto right-[-60px] ${toggelProfile ? "block" : "hidden "
                  }`}
              >
                <ul className="menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content">
                  <li>
                    {user ? (
                      <div className="mb-1">
                        {" "}
                        <i className="fa-solid fa-right-from-bracket"></i>
                        <button
                          onClick={() => {
                            signOut(auth);
                            localStorage.removeItem("accessToken");
                            // localStorage.removeItem('buy-now');
                          }}
                        >
                          Logout
                        </button>
                      </div>
                    ) : (
                      <Link className="mb-1" to="/login">
                        <i className="fa-solid fa-right-to-bracket"></i>{" "}
                        <p>Login</p>
                      </Link>
                    )}
                  </li>
                  <li>
                    <Link className="mb-1" to="/profile">
                      <i className="fa-solid fa-user"></i>
                      <p>Profile</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="relative">
              <Link to="/wishList">
                {" "}
                <i className=" text-white hover:text-[#DADADA] cursor-pointer fa-regular fa-heart"></i>
              </Link>
              <div className="absolute top-[-13px] right-[-12px]">
                <div className="bg-orange-400 rounded-full flex justify-center items-center text-sm h-[18px] w-[18px]">
                  {/* {whishList.length} */}
                  {whishList?.reduce(
                    (sum, current) => sum + current.quantity,
                    0
                  )}
                </div>
              </div>
            </div>
            <div className="relative mr-2 md:mr-0">
              <i
                onClick={() => {
                  setToggelProfile(false);
                  setToggelCart(!toggelCart);
                }}
                className=" text-white  hover:text-[#DADADA] cursor-pointer fa-solid fa-cart-shopping"
              ></i>
              <div className="absolute top-[-13px] right-[-12px]">
                <div className="bg-orange-400 rounded-full flex justify-center items-center text-sm h-[18px] w-[18px]">
                  {cart?.reduce((sum, current) => sum + current.quantity, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed top-[75px] right-0 z-[99999999999] ease-in-out duration-300 ${toggelCart ? "mr-0" : "mr-[-500px] "
          }`}
      >
        <Cart
          cartProduct={data}
          handelDeleteShoppingCart={handelDeleteShoppingCart}
          refetch={refetch}
          handelDeleteItem={handelDeleteItem}
        ></Cart>
      </div>
    </div>
  );
};

export default TopHeader;
