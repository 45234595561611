import { useEffect, useState } from "react";
import { getShoppingWishList } from "../Utilitis/AddtoWishList";

const useWishLish = (products) => {
    const [whishList, setWhishList] = useState([]);

    useEffect(() => {
        const storedWhishList = getShoppingWishList();
        const saveWhishList = [];
        for (const id in storedWhishList) {
            const storeProduct = products?.find(product => product?._id === id);
            if (storeProduct) {
                const quantity = storedWhishList[id];
                storeProduct.quantity = quantity;
                saveWhishList.push(storeProduct);

            }
        }
        setWhishList(saveWhishList);

    }, [products]);
    return [whishList, setWhishList, products];
}
export { useWishLish };