import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat/lib/MessengerCustomerChat';

const Facebook = () => {

    return (
        <div>
            <MessengerCustomerChat
                pageId="104678305293568"
                appId="689251689235001"
                // htmlRef="<REF_STRING>"
            />
        </div>
    );
};

export default Facebook;