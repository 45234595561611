import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCustomerInfo from "../../Hooks/useCustomerInfo";
import ButtonSpinner from "../../Shared/ButtonSpinner";
import Spinner from "../../Shared/Spinner/Spinner";
import { getBuyNow } from "../../Utilitis/buyNow";
import auth from "../../firebase.init";

const CheckOut = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [state, setState] = useState([]);
  const [user, loading] = useAuthState(auth);
  const [customerInfo, customerLoading] = useCustomerInfo(user?.email);
  const [singleSate, setSingleState] = useState(customerInfo?.address?.state);
  const [singleCity, setSingleCity] = useState(customerInfo?.address?.city);
  const navigation = useNavigate();
  const [codeInput, setCodeInput] = useState("");
  const [couponMessage, setCouponMessage] = useState("");
  const [couponPrice, setCouponPrice] = useState(0);
  const [paymentType, setPaymenttype] = useState("pay");
  const [paymentLoading, setPatmentLoading] = useState(false);
  const navigate = useNavigate();
  const [polecy, setPolicy] = useState(false);
  useEffect(() => {
    setSingleState(customerInfo?.address?.state);
    setSingleCity(customerInfo?.address?.city);
  }, [customerInfo]);

  useEffect(() => {
    fetch("/fakeCity.json")
      .then((res) => res.json())
      .then((stateData) => setState(stateData));
  }, [singleSate]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const city = state?.find((city) => city.state === singleSate);
  const Pdata = getBuyNow();
  const { isLoading, data } = useQuery(["product"], () =>
    fetch(
      `https://api.shopinshop.com.bd/product/client/public/${Pdata.productId}`
    ).then((res) => res.json())
  );
  if (isLoading || loading || customerLoading) {
    return <Spinner></Spinner>;
  }
  const applyCoupon = (code) => {
    fetch(
      `https://api.shopinshop.com.bd/coupon/public/verify/${code}?pId=${Pdata.productId}&email=${data?.marchentEmail}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        if (result.message) {
          setCouponMessage(result.message);
          setCouponPrice(0);
        } else {
          setCouponPrice(parseInt(result));
          setCouponMessage("");
        }
      });
  };

  // data.quantity = Pdata.newQuentity;

  const productPrice = data.sPrice
    ? +Pdata.newQuentity * +data?.sPrice
    : +Pdata.newQuentity * +data.price;
  const shippingCharge =
    singleSate === "Dhaka" && singleCity === "Dhaka"
      ? data.deliveryInDhaka
      : data.outDhaka;
  // const vat = (+productPrice + +shippingCharge) * 0.15;

  // const total = couponPrice ? (+productPrice + +shippingCharge + vat) - ((+productPrice + +shippingCharge + vat) * (couponPrice / 100)) : +productPrice + +shippingCharge + vat;
  const total = couponPrice
    ? +productPrice +
    +shippingCharge -
    (+productPrice + +shippingCharge) * (couponPrice / 100)
    : +productPrice + +shippingCharge;
  const percentPrice = total * 0.25;
  const totalPayable = data.orderType === "Pre-Order" ? percentPrice : +total;
  const due =
    data.orderType === "Pre-Order"
      ? +total - percentPrice
      : paymentType === "Pay"
        ? 0
        : +total;

  const buysubmit = async (event) => {
    event.preventDefault();
    // console.log('order time date post', event.target.data)
    const customerName = event.target.name.value;
    const customerEmail = customerInfo.email;
    const customerPhone = event.target.phone.value;
    const customerState = event.target.state.value;
    const customerCity = event.target.city.value;
    const customerDetails = event.target.address.value;
    const customerNote = event.target.note.value;
    const save = event.target.saveAddress.checked;
    const orderID = data?.sku + Date.now();

    const color = Pdata?.color;
    const size = Pdata?.size;
    const deliveryCharge = shippingCharge;
    const totalPrice = total;
    // const vatPrice = vat.toFixed(2);
    const duePrice = due;
    const isCoupon = couponPrice;
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    const time = today.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    const products = [
      {
        productId: Pdata.productId,
        productName: data?.productName,
        productImage: data?.primaryImage,
        quantity: Pdata?.newQuentity,
        deliveryCharge: shippingCharge,
        productPrices: parseInt(data?.sPrice) || parseInt(data?.price),
        productTotalPrice:
          (parseInt(data?.sPrice) || parseInt(data?.price)) *
          Pdata?.newQuentity,
        orderType: data?.orderType,
        status: "Pending",
        marchentEmail: data?.marchentEmail,
        shopPhone: data?.marchantPhone,
        shopName: data?.marchentShop,
      },
    ];

    const address = {
      state: customerState,
      city: customerCity,
      detail: customerDetails,
    };
    if (save) {
      fetch(
        `https://api.shopinshop.com.bd/customer/info/update/${user?.email}`,
        {
          method: "PUT",
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "content-type": "application/json",
          },
          body: JSON.stringify(address),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.acknowledged) {
            toast("Your new address has been saved!!");
          }
        });
    }

    const orderInfo = {
      customerName,
      customerEmail,
      customerPhone,
      customerState,
      customerCity,
      customerDetails,
      customerNote,
      orderID,
      color,
      size,
      deliveryCharge,
      totalPrice,
      totalPayable,
      duePrice,
      isCoupon,
      date,
      time,
      status: "Pending",
      paymentDone: false,
      orderValue: "Single",
      products,
    };
    // const orderInfo = { customerName, customerEmail, customerPhone, customerState, customerCity, customerDetails, customerNote, orderID, color, size, deliveryCharge, totalPrice, totalPayable, vatPrice, duePrice, isCoupon, date, time, status: 'Pending', paymentDone: false, orderValue: 'Single', products }

    if (data.orderType === "Pre-Order" || paymentType === "Pay") {
      setPatmentLoading(true);
      const paymentInfo = { ...orderInfo, paymentMethod: "Pending" };
      fetch("https://api.shopinshop.com.bd/online-payment/public", {
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(paymentInfo),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.data) {
            window.location.replace(result.data);
            setPatmentLoading(false);
          } else {
            toast("Something is Wrong! Please Try Again");
            setPatmentLoading(false);
          }
        });
    } else {
      setPatmentLoading(true);
      const cashInfo = {
        ...orderInfo,
        paymentDone: false,
        paymentMethod: "Cash On Delivery",
        payment: "Due",
      };
      fetch(`https://api.shopinshop.com.bd/order/public`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(cashInfo),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.acknowledged) {
            toast("Your Order Place Successfully!!");
            setPatmentLoading(false);
            navigate(`/COD-orderSuccess/${cashInfo.orderID}`);
          }
        });
    }
  };

  return (
    <div className="px-5 md:px-10 my-10">
      <Helmet>

        <title>Shop In Shop | CheckOut</title>
      </Helmet>
      <form onSubmit={buysubmit}>
        <div className="grid grid-cols-1 pl-8 md:grid-cols-2 gap-4">
          <div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Name *</span>
              </label>
              <input
                type="text"
                defaultValue={customerInfo?.name}
                placeholder="Your Name"
                name="name"
                className="input input-bordered w-full"
                required
              />{" "}
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Phone *</span>
              </label>
              <input
                type="text"
                placeholder="Your Phone"
                name="phone"
                className="input input-bordered w-full"
                required
              />{" "}
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <input
                type="text"
                value={customerInfo?.email}
                placeholder="Your Email"
                className="input input-bordered w-full"
                readOnly
                disabled
              />
            </div>

            <div className="flex justify-between gap-4">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">State *</span>
                </label>
                <select
                  defaultValue={customerInfo?.address?.state}
                  onChange={(e) => setSingleState(e.target.value)}
                  name="state"
                  className="select select-bordered"
                  required
                >
                  <option selected disabled>
                    {customerInfo?.address?.state || "Pick one"}
                  </option>
                  {state.map((sates) => (
                    <option defaultValue={sates.state} key={sates._id}>
                      {sates.state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">City *</span>
                </label>
                <select
                  defaultValue={customerInfo?.address?.city}
                  onChange={(e) => setSingleCity(e.target.value)}
                  name="city"
                  className="select select-bordered"
                  required
                >
                  <option disabled selected>
                    {" "}
                    {customerInfo?.address?.city || "Pick one"}
                  </option>
                  {city?.city.map((city, index) => (
                    <option key={index}>{city}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Your Address *</span>
              </label>
              <textarea
                defaultValue={customerInfo?.address?.detail}
                className="textarea textarea-bordered h-24"
                name="address"
                placeholder="Your Address"
                required
              ></textarea>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Special Note</span>
              </label>
              <textarea
                className="textarea textarea-bordered h-24"
                name="note"
                placeholder="Special Note"
              ></textarea>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Save Address Your Profile ?</span>
                <input
                  type="checkbox"
                  name="saveAddress"
                  className="checkbox"
                />
              </label>
            </div>

            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text font-semibold">
                  Do you have a coupon?
                </span>
              </label>
              <div className="flex">
                <input
                  onChange={(e) => setCodeInput(e.target.value)}
                  type="text"
                  name="coupon"
                  placeholder="Coupon Code"
                  className="input input-bordered w-full max-w-xs"
                />
                <span
                  onClick={() => applyCoupon(codeInput)}
                  className="ml-2 btn btn-primary"
                >
                  Apply
                </span>
              </div>
              {couponMessage && (
                <label className="label">
                  <span className="label-text-alt text-orange-500">
                    {couponMessage}
                  </span>
                </label>
              )}
            </div>
          </div>
          <div className="justify-end">
            <div className="card m-4 md:m-10 bg-base-100 shadow-xl">
              <figure className="px-10">
                <img
                  src={`https://api.shopinshop.com.bd/${data.primaryImage}`}
                  width="100px"
                  alt={data.productName}
                  className="rounded-xl"
                />
              </figure>
              <div className="card-body items-center text-center">
                <h2
                  onClick={() => navigation(`/singleProduct/${data._id}`)}
                  className="hover:text-orange-500 cursor-pointer font-semibold"
                >
                  {data.productName}
                </h2>

                <div className="flex justify-center">
                  <div>
                    <p className="mr-2">
                      {Pdata.color && `Color : ${Pdata.color}`}
                    </p>
                  </div>
                  <div>
                    <p className="mr-2">
                      {Pdata.size && `Size : ${Pdata.size}`}
                    </p>
                  </div>
                  <div>
                    <p className="mr-2">
                      {Pdata.newQuentity && `Quantity : ${Pdata.newQuentity}`}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-orange-500">
                    {" "}
                    {data.orderType === "Pre-Order"
                      ? "Estimated Delivery 7-14 days"
                      : singleSate === "Dhaka" &&
                        singleCity === "Dhaka" &&
                        data.orderType === "COD"
                        ? "Estimated Delivery 3-5 days"
                        : "Estimated Delivery 5-7 days"}{" "}
                  </p>
                </div>
                <div className="flex w-full justify-between mt-6">
                  <p>
                    <b>Subtotal</b>{" "}
                  </p>
                  <p>
                    {Pdata.newQuentity} X{" "}
                    {data.sPrice ? data.sPrice : data.price} = {productPrice}{" "}
                    &#2547;
                  </p>
                </div>
                <div className="w-full px-20">
                  <hr></hr>
                </div>
                <div className="flex w-full justify-between">
                  <p>
                    <b>Shipping</b>{" "}
                  </p>
                  <p>{shippingCharge} &#2547;</p>
                </div>
                {/* <div className='w-full px-20'><hr></hr></div>

                                <div className='flex w-full justify-between'>
                                    <p><b>Vat</b> </p>
                                    <p>{vat.toFixed(2)} &#2547;</p>
                                </div> */}

                {couponPrice ? (
                  <div className="w-full px-20">
                    <hr></hr>
                  </div>
                ) : (
                  ""
                )}

                {couponPrice ? (
                  <div
                    className={`flex w-full justify-between ${couponPrice || "hidden"
                      }`}
                  >
                    <p>
                      <b>Coupon</b>{" "}
                    </p>
                    <p> -{couponPrice} %</p>
                  </div>
                ) : (
                  ""
                )}
                <div className="w-full px-20">
                  <hr></hr>
                </div>

                <div className="flex w-full justify-between">
                  <p>
                    <b>Total</b>{" "}
                  </p>
                  <p>{Math.round(total.toFixed(2))} &#2547;</p>
                </div>

                <div className="w-full px-20">
                  <hr></hr>
                </div>

                <div className="flex w-full justify-between">
                  <p>
                    <b>Payable Total</b>{" "}
                  </p>
                  <p>
                    {" "}
                    <b>{Math.round(totalPayable.toFixed(2))}</b> &#2547;
                  </p>
                </div>
                <div className="w-full px-20">
                  <hr></hr>
                </div>

                {/* {
                                    data.orderType === 'Pre-Order' && <div>
                                        <div className='flex w-full justify-between'>
                                            <p><b>Total Due</b> </p>
                                            <p> <b>{due.toFixed(2)}</b> &#2547;</p>
                                        </div>
                                        <div className='w-full px-20'><hr></hr></div>
                                    </div>
                                } */}
                <div>
                  <div className="flex w-full justify-between">
                    <p>
                      <b>Total Due</b>{" "}
                    </p>
                    <p>
                      {" "}
                      <b>{Math.round(total.toFixed(2))}</b> &#2547;
                    </p>
                  </div>
                  <div className="w-full px-20">
                    <hr></hr>
                  </div>
                </div>
              </div>

              {data.orderType === "Pre-Order" || (
                <div className="flex justify-center mb-10">
                  <div className="form-control w-full max-w-xs">
                    <select
                      onChange={(e) => setPaymenttype(e.target.value)}
                      className="select select-bordered"
                    >
                      <option value="COD">Cash on Delivery</option>
                      <option value="Pay">Payment Online</option>
                    </select>
                  </div>
                </div>
              )}

              {productPrice > 0 && (
                <>
                  <div className="form-control ml-6 mb-2">
                    <label className="flex items-center cursor-pointer">
                      <input
                        onChange={(e) => setPolicy(e.target.checked)}
                        type="checkbox"
                        className="checkbox"
                      />

                      <span className="label-text ml-4">
                        {" "}
                        Agree to the{" "}
                        <a
                          className="underline text-orange-500 hover:cursor-pointer"
                          href="/terms"
                          target="_new"
                        >
                          Terms & Conditions
                        </a>
                        ,{" "}
                        <a
                          className="underline text-orange-500 hover:cursor-pointer"
                          href="/privacy"
                          target="_new"
                        >
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a
                          className="underline text-orange-500 hover:cursor-pointer"
                          href="/returnRefund"
                          target="_new"
                        >
                          Return & Refund Policy
                        </a>{" "}
                      </span>
                    </label>
                  </div>
                  {paymentType !== "Pay" && data?.orderType !== "Pre-Order" ? (
                    <button
                      className={`btn btn-primary ${paymentLoading ? "hidden" : "block"
                        }`}
                      disabled={!polecy}
                    >
                      Confirm Order
                    </button>
                  ) : (
                    <button
                      className={`btn btn-primary ${paymentLoading ? "hidden" : "block"
                        }`}
                      disabled={!polecy}
                    >
                      Payment Now
                    </button>
                  )}
                </>
              )}

              {paymentLoading && (
                <div className="py-4">
                  <ButtonSpinner></ButtonSpinner>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CheckOut;
