import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import auth from '../../firebase.init';
import useCustomerInfo from '../../Hooks/useCustomerInfo';
import Spinner from '../../Shared/Spinner/Spinner';

const MainProfile = () => {
    const [user, loading] = useAuthState(auth);
    const [customerInfo, isloading] = useCustomerInfo(user?.email);
    if (loading || isloading) {
        return <Spinner></Spinner>
    }
    const memberAt = new Date(customerInfo.creationTime);


    return (
        <div>
            <div className="card w-96 bg-base-100 py-10 my-10 md:my-22 mx-auto shadow-xl">
                <div>
                    <div className="flex justify-center">
                        {
                            customerInfo?.img ? <img className="mask mask-circle w-28" src={customerInfo?.img} alt="profile photos" /> : <i className="fas fa-user"></i>
                        }

                    </div>
                    <div className="card-body items-center text-center">
                        <h2 className="card-title">{customerInfo?.name}</h2>
                        <p>{customerInfo?.email}</p>
                        <p>Member At : {memberAt.getDate()} / {memberAt.getMonth()} / {memberAt.getFullYear()}</p>
                        {
                            customerInfo?.address && <div className='text-justify'>
                                <p> State : {customerInfo.address.state} </p>
                                <p> City : {customerInfo.address.city} </p>
                                <p> Address :{customerInfo.address.detail} </p>

                            </div>
                        }

                        <div className="card-actions mt-4">

                            {
                                customerInfo?.address ? <Link to="address" className="btn btn-primary">Update Address</Link> : <Link to="address" className="btn btn-primary">ADD Address</Link>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainProfile;