import React from "react";
import { Link } from "react-router-dom";
import "../../Shared/Header/Header.css";

const MenuItem = ({ category, index }) => {
  // console.log('category name', category)

  return (
    <div className="relative group">
      <div className="">
        <Link
          to={`/main-category/${category._id}?name=${category.name}?banner=${category.banner ? category.banner : ''}`}
          tabIndex="0"
          className="cursor-pointer hover:overline underline-offset-8"
        >
          {category.name}
        </Link>
      </div>

      <div className="fixed left-0 w-screen hidden group-hover:block bg-white z-[99999999999999999999999999999]">
        <ul
          tabIndex="0"
          className="menu p-2  shadow-lg bg-base-100 overflow-auto rounded-box h-[350px]"
        >
          <div className="flex flex-wrap">
            {category?.category.map(
              (item, index) =>
                item.status && (
                  <li key={index}>
                    <Link
                      to={`/category/${item.slug}?id=${category._id}&banner=${item.banner ? item.banner : ''}`}
                      title={item.name}
                      className=" text-primary text-[12px] font-bold"
                    >
                      {item.name.length > 10 ? item.name : item.name}
                    </Link>

                    {item.subCategory.map((sub, index) => (
                      <Link
                        to={`/sub-category/${sub.slug}?id=${category._id}&banner=${sub.banner ? sub.banner : ''}`}
                        key={index}
                        className="text-black text-[12px]"
                      >
                        {sub.name}
                      </Link>
                    ))}
                  </li>
                )
            )}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default MenuItem;
