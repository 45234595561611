import { useEffect, useState } from "react";

const useToken = (user) => {
    const [token, setToken] = useState('');


    useEffect(() => {

        const email = user?.user?.email;
        const creationTime = user?.user?.metadata?.creationTime;
        const currentUser = {
            email: email, creationTime: creationTime
        }

        if (email) {
            fetch(`https://api.shopinshop.com.bd/customer/public/${email}`, {
                method: "PUT",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(currentUser),
            })
                .then(res => res.json())
                .then(data => {
                    const accessToken = data.token;
                    localStorage.setItem("accessToken", accessToken);
                    setToken(accessToken);

                })
        }
    }, [user])

    return [token];
}
export default useToken;