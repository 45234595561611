import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const CodOrderSuccess = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const { OrderID } = useParams();
    const navigate = useNavigate();
    return (
        <div className=' h-screen'>
            <div className="mt-4 md:mt-28 card w-1/2 mx-auto bg-primary items-center text-primary-content">
                <div className="card-body">
                    <h2 className="card-title">Congratulations Your Order Successfully</h2>
                    <p>Your Order ID: <span className='text-orange-500'>{OrderID}</span></p>
                    <div className="card-actions mt-6 justify-center">
                        <button onClick={() => navigate('/allProduct')} className="btn">More Product</button>
                        <button onClick={() => navigate('/profile/order')} className="btn">Order Page</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CodOrderSuccess;