
import { useQuery } from "react-query";

const useCategory = () => {
    const { isLoading, data, refetch } = useQuery('categorys', () =>
        fetch(`https://api.shopinshop.com.bd/category/client`)
            .then(res => res.json())
    );

    return [data, isLoading, refetch]
}
export default useCategory;