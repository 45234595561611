import Carousel from 'nuka-carousel';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use-media';
import { cartContext } from '../../App';
import Spinner from '../../Shared/Spinner/Spinner';
import { addToWishLocal } from '../../Utilitis/AddtoWishList';
import { addToCartLocal } from '../../Utilitis/addToCartLocal';
import MobileProductCart from '../Cart/MobileProductCart';
import ProductCart from '../Cart/ProductCart';

const FetureProduct = () => {

  const isTab = useMedia('(min-width: 900px) and (max-width: 1320px)');
  const isMobile = useMedia("(min-width: 588px) and (max-width: 900px)");
  const isSmallMobile = useMedia("(min-width: 100px) and (max-width: 588px)");
  const large = useMedia("(min-width: 1320px) and (max-width: 20000px)");

  const { isLoading, data } = useQuery('TopRatingProducts', () =>
    fetch(`https://api.shopinshop.com.bd/top/reating/product/client`)
      .then(res => res.json())
  );
  // console.log(data)

  const carts = useContext(cartContext);
  const [cart, setCartValue, setCart, whishList, setWhishList] = carts;
  setCartValue(data);
  const navigate = useNavigate();

  // const datas = data?.filter(product => product.avgRating >=0);
  // console.log(datas)
  if (isLoading) {
    return <Spinner></Spinner>
  };

  const handelCart = (product) => {
    let newCart = [];
    const exists = cart.find(cartProduct => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newCart = [...cart, product];
    }
    else {
      const rest = cart.filter(cartProduct => cartProduct._id !== product._id);
      product.quantity += 1;
      newCart = [...rest, exists];
    }

    if (!isLoading) {
      setCart(newCart);
      addToCartLocal(product._id);
    }

  }


  const handelWhishList = (product) => {
    let newWishList = [];
    const exists = whishList.find(cartProduct => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newWishList = [...whishList, product];
    }
    else {
      const rest = whishList.filter(cartProduct => cartProduct._id !== product._id);
      product.quantity += 1;
      newWishList = [...rest, exists];
    }

    if (!isLoading) {
      setWhishList(newWishList);
      addToWishLocal(product._id);
    }
  }

  const handelSingleProduct = id => {
    navigate(`/singleProduct/${id}`)
  }

  return (
    <div className="mx-auto mx-5">
      <Carousel
        className="pb-10"
        wrapAround={true}
        slidesToShow={
          isTab ? 3 : isMobile ? 2 : isSmallMobile ? 2 : large ? 6 : 8
        }
        defaultControlsConfig={{
          nextButtonText: "Custom Next",
          prevButtonText: "Custom Prev",
          pagingDotsStyle: {
            display: "none",
          },
        }}
        renderCenterRightControls={({ nextSlide }) => (
          <button onClick={nextSlide}>
            <i className="far fa-arrow-alt-circle-right"></i>
          </button>
        )}
        renderCenterLeftControls={({ previousSlide }) => (
          <button onClick={previousSlide}>
            <i className="far fa-arrow-alt-circle-left"></i>
          </button>
        )}
      >
        {data
          ?.slice(0, 10)
          .map((product) =>
            isSmallMobile ? (
              <MobileProductCart
                className="mx-2 pb-2"
                product={product}
                handelWhishList={handelWhishList}
                handelSingleProduct={handelSingleProduct}
                handelCart={handelCart}
                isMobile={isMobile}
              ></MobileProductCart>
            ) : (
              <ProductCart
                className="mx-4"
                product={product}
                handelWhishList={handelWhishList}
                handelSingleProduct={handelSingleProduct}
                handelCart={handelCart}
                isMobile={isMobile}
              ></ProductCart>
            )
          )
          .reverse()}
      </Carousel>
    </div>
  );
};

export default FetureProduct;