import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Career = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
      <div className="flex items-center justify-center h-screen">
        <Helmet>
          <title>Shop In Shop | Career</title>
        </Helmet>
        <p className="text-xl">Join With us and let’s play together </p>
      </div>
    );
};

export default Career;