import React from 'react';
import YouTube from 'react-youtube';

const YoutubeVideo = ({ openVideo }) => {
    const opts = {
        height: '390',
        width: '100%',
        playerVars: {
            autoplay: 0,
        }
    };
    return (
        <div>
            <input type="checkbox" id="youtube-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <YouTube videoId={openVideo} opts={opts} />
                    <div className="modal-action">
                        <label htmlFor="youtube-modal" className="btn btn-primary">Cancel</label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YoutubeVideo;