const addToCartLocal = (id, refetch) => {
    let shoppingCart = getShoppingCart();
    const quantity = shoppingCart[id];
    if (quantity) {
        const newQuantity = quantity + 1;
        shoppingCart[id] = newQuantity;
        // refetch()
    }
    else {
        shoppingCart[id] = 1;
        // refetch()
    }
    localStorage.setItem('shopping-cart', JSON.stringify(shoppingCart));
};



const getShoppingCart = () => {
    let shoppingCart = {};

    const localStore = localStorage.getItem('shopping-cart');
    if (localStore) {
        shoppingCart = JSON.parse(localStore);
    }

    return shoppingCart;
};


const deleteItemByID = (id) => {
    const storedCart = localStorage.getItem('shopping-cart');
    if (storedCart) {
        const shoppingCart = JSON.parse(storedCart);
        if (id in shoppingCart) {
            delete shoppingCart[id];
            localStorage.setItem('shopping-cart', JSON.stringify(shoppingCart));
        }
    }
};


const deleteLocalStore = () => {
    localStorage.removeItem('shopping-cart');
}



const increaseQuantity = (id, inputQuantity) => {
    let shoppingCart = getShoppingCart();
    const quantity = shoppingCart[id];
    if (quantity) {
        const newQuantity = parseInt(inputQuantity);
        shoppingCart[id] = newQuantity;
    }
    else {
        shoppingCart[id] = 1;
    }


    localStorage.setItem('shopping-cart', JSON.stringify(shoppingCart));
}

export { addToCartLocal, getShoppingCart, deleteItemByID, deleteLocalStore, increaseQuantity };
