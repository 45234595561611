import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Cart = (props) => {
  const { cartProduct } = props;
  const { handelDeleteItem } = props;
  const { handelDeleteShoppingCart } = props;




  const navigation = useNavigate();
  return (

    <div>
      {
        cartProduct ? <div className="shadow-lg p-4  bg-white">
          <h2 className="text-2xl text-textHeading font-semibold">Order Summery</h2>
          {
            cartProduct.length <= 0 ? <div className="h-80 w-96 flex items-center justify-center">
              <p className="my-6 text-orange-500 font-bold">There is no product in your cart</p>
            </div> : <div className="h-80 overflow-y-auto">
              <div>
                {
                  cartProduct.message || cartProduct?.map(product => <div className="flex border-2 rounded-lg h-16 w-96 my-4 px-4 justify-between items-center" key={product._id}>
                    <img src={`https://api.shopinshop.com.bd/${product.primaryImage}`} width='50' alt={product.productName} />
                    <p title={product.productName} onClick={() => {
                      navigation(`/singleProduct/${product._id}`)
                    }
                    } className="cursor-pointer hover:text-orange-500 capitalize"> {product?.productName.length > 30 ? product?.productName.slice(0, 20) + '....' : product.productName}</p>
                    {
                      product?.sPrice ? <p className="mx-4">{product.quantity} X {product.sPrice}</p> : <p className="mx-4">{product.quantity} X {product.price}</p>
                    }

                    <i onClick={() => handelDeleteItem(product._id)} className="cursor-pointer hover:text-orange-500 far fa-trash-alt"></i>
                  </div>)
                }
              </div>

              <div className="flex mt-4">
                <button onClick={handelDeleteShoppingCart} className='btn btn-primary mr-4'>
                  <div className='align-icon'>
                    <p>Clear Cart</p>
                  </div>

                </button><br></br>
                <Link to='/viewCart' className='btn btn-primary'>
                  <p>View Cart</p>
                </Link>
              </div>
            </div >
          }
        </div> : <div className="flex h-80 w-96 items-center justify-center ">
          <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
        </div>
      }
    </div>



  );
};

export default Cart;
