import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const TrackOrder = () => {
    const { orderId } = useParams();
    const [track, setTrack] = useState([]);
    console.log(track)

    useEffect(() => {
        fetch(`https://api.shopinshop.com.bd/orders/track/${orderId}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                setTrack(data);
            })
    }, [orderId]);

    return (
        <div>

            <div className="hero py-10 bg-base-200">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <div className="text-center lg:text-left">
                        <div className="overflow-x-auto w-full">
                            <table className="table w-full">

                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Shop Name</th>
                                        <th>Quantity</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        track?.products?.map((product, index) => <tr key={index}>

                                            <td>
                                                <div className="flex items-center space-x-3">
                                                    <div className="avatar">
                                                        <div className="mask mask-squircle w-12 h-12">
                                                            <img src={`https://api.shopinshop.com.bd/${product?.productImage}`} alt="Avatar Tailwind CSS Component" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div title={product?.productName} className="font-bold cursor-pointer hover:text-orange-500">{product?.productName?.slice(0, 20) + '...'}</div>
                                                        {/* <div className="text-sm opacity-50">{product?.status}</div> */}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {product.shopName}
                                                <br />
                                                <span className="badge badge-ghost badge-sm">{product.marchentEmail}</span>
                                            </td>
                                            <td>{product.quantity}</td>
                                            <td className="px-6 py-4">
                                                <div className="flex gap-x-4 justify-between">
                                                    <label htmlFor="my-modal-60">
                                                        <i
                                                            // onClick={() => setOpenOrderModal(order)}
                                                            className="cursor-pointer fa-solid fa-eye"
                                                        ></i>
                                                    </label>
                                                    <label htmlFor="pdf-modal">
                                                        <i
                                                            // onClick={() => setOpenOrderModal(order)}
                                                            className="text-orange-500 fas fa-file-alt cursor-pointer"
                                                        ></i>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>)
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
                        <div className='flex justify-center lg:py-10'>
                            {
                                track?.status === 'Self' ? <ul className="steps steps-vertical">
                                    <li className="step">Pending</li>
                                    <li className="step">Processing</li>
                                    <li className="step">Processing for Kolkata</li>
                                    <li className="step">Recived at Kolkata</li>
                                    <li className="step">Recived at Dhaka</li>
                                    <li className="step">Processing for delivery</li>
                                    <li className="step">Delivered</li>
                                    <li className="step">Completed</li>
                                </ul> : track?.status === 'Pending' ? <ul className="steps steps-vertical">
                                    <li className="step step-primary">Pending</li>
                                    <li className="step">Processing</li>
                                    <li className="step">Processing for Kolkata</li>
                                    <li className="step">Recived at Kolkata</li>
                                    <li className="step">Recived at Dhaka</li>
                                    <li className="step">Processing for delivery</li>
                                    <li className="step">Delivered</li>
                                    <li className="step">Completed</li>
                                </ul> : track?.status === 'Processing' ? <ul className="steps steps-vertical">
                                    <li className="step step-primary">Pending</li>
                                    <li className="step step-primary">Processing</li>
                                    <li className="step">Processing for Kolkata</li>
                                    <li className="step">Recived at Kolkata</li>
                                    <li className="step">Recived at Dhaka</li>
                                    <li className="step">Processing for delivery</li>
                                    <li className="step">Delivered</li>
                                    <li className="step">Completed</li>
                                </ul> : track?.status === 'Processing for Kolkata' ? <ul className="steps steps-vertical">
                                    <li className="step step-primary">Pending</li>
                                    <li className="step step-primary">Processing</li>
                                    <li className="step step-primary">Processing for Kolkata</li>
                                    <li className="step">Recived at Kolkata</li>
                                    <li className="step">Recived at Dhaka</li>
                                    <li className="step">Processing for delivery</li>
                                    <li className="step">Delivered</li>
                                    <li className="step">Completed</li>
                                </ul> : track?.status === 'Received at Kolkata' ? <ul className="steps steps-vertical">
                                    <li className="step step-primary">Pending</li>
                                    <li className="step step-primary">Processing</li>
                                    <li className="step step-primary">Processing for Kolkata</li>
                                    <li className="step step-primary">Received at Kolkata</li>
                                    <li className="step">Received at Dhaka</li>
                                    <li className="step">Processing for delivery</li>
                                    <li className="step">Delivered</li>
                                    <li className="step">Completed</li>
                                </ul> : track?.status === 'Received at Dhaka' ? <ul className="steps steps-vertical">
                                    <li className="step step-primary">Pending</li>
                                    <li className="step step-primary">Processing</li>
                                    <li className="step step-primary">Processing for Kolkata</li>
                                    <li className="step step-primary">Recived at Kolkata</li>
                                    <li className="step step-primary">Recived at Dhaka</li>
                                    <li className="step">Processing for delivery</li>
                                    <li className="step">Delivered</li>
                                    <li className="step">Completed</li>
                                </ul> : track?.status === 'Processing for Delivering' ? <ul className="steps steps-vertical">
                                    <li className="step step-primary">Pending</li>
                                    <li className="step step-primary">Processing</li>
                                    <li className="step step-primary">Processing for Kolkata</li>
                                    <li className="step step-primary">Recived at Kolkata</li>
                                    <li className="step step-primary">Recived at Dhaka</li>
                                    <li className="step step-primary">Processing for delivery</li>
                                    <li className="step">Delivered</li>
                                    <li className="step">Completed</li>
                                </ul> : track?.status === 'Delivered' ? <ul className="steps steps-vertical">
                                    <li className="step step-primary">Pending</li>
                                    <li className="step step-primary">Processing</li>
                                    <li className="step step-primary">Processing for Kolkata</li>
                                    <li className="step step-primary">Recived at Kolkata</li>
                                    <li className="step step-primary">Recived at Dhaka</li>
                                    <li className="step step-primary">Processing for delivery</li>
                                    <li className="step step-primary">Delivered</li>
                                    <li className="step">Completed</li>
                                </ul> : track?.status === 'Completed' ? <ul className="steps steps-vertical">
                                    <li className="step step-primary">Pending</li>
                                    <li className="step step-primary">Processing</li>
                                    <li className="step step-primary">Processing for Kolkata</li>
                                    <li className="step step-primary">Recived at Kolkata</li>
                                    <li className="step step-primary">Recived at Dhaka</li>
                                    <li className="step step-primary">Processing for delivery</li>
                                    <li className="step step-primary">Delivered</li>
                                    <li className="step step-primary">Completed</li>
                                </ul> : <li data-content="✕" className="step step-neutral">Cancel</li>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackOrder;