import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import ButtonSpinner from '../../Shared/ButtonSpinner';
import Spinner from '../../Shared/Spinner/Spinner';
import auth from '../../firebase.init';

const AddressUpdate = ({ addressUpdate: customerInfo, setAddressUpdate, refetch, refetchss }) => {
    const [user, loading] = useAuthState(auth);
    const [state, setState] = useState([]);
    const [singleSate, setSingleState] = useState('');
    const [singleCity, setSingleCity] = useState('');
    const [allAddress, setAllAddress] = useState('')
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        fetch('/fakeCity.json')
            .then(res => res.json())
            .then(stateData => setState(stateData));
    }, [singleSate]);
    const city = state?.find(city => city.state === singleSate);

    const handelAddress = event => {
        event.preventDefault();
        const inputSate = event.target.inputState.value || singleSate;
        const inputCity = event.target.inputCity.value || singleCity;
        const allAddres = event.target.detail.value || allAddress;
        const address = { state: inputSate, city: inputCity, detail: allAddres }
        setSpinner(true);
        fetch(`https://api.shopinshop.com.bd/customer/info/update/${user?.email}`, {
            method: "PUT",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify(address)
        })
            .then(res => res.json())
            .then(data => {
                if (data.acknowledged) {
                    toast('Your address has been saved');
                    refetch();
                    refetchss();
                    setAddressUpdate(null);
                    setSpinner(false);
                }
            })


    }

    if (loading) {
        return <Spinner></Spinner>
    }

    return (
        <div>

            <input type="checkbox" id="address-update-modal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <form onSubmit={handelAddress}>
                        <div className='flex justify-between gap-4'>
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">State</span>
                                </label>
                                <select defaultValue={customerInfo?.address?.state} onChange={(e) => setSingleState(e.target.value)} name="inputState" className="select select-bordered"
                                    required
                                >
                                    <option disabled selected >{customerInfo?.address?.state || 'Pick One'}</option>
                                    {
                                        state.map(sates => <option key={sates._id}>{sates.state}</option>)
                                    }
                                </select>

                            </div>
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">City</span>
                                </label>
                                <select defaultValue={customerInfo?.address?.city} onChange={(e) => setSingleCity(e.target.value)} name="inputCity" className="select select-bordered"
                                    required
                                >
                                    <option disabled selected>{customerInfo?.address?.city || 'Pick one'}</option>
                                    {
                                        city?.city.map((city, index) => <option key={index}>{city}</option>
                                        )
                                    }
                                </select>

                            </div>
                        </div>

                        <div className="form-control">
                            <label className="label">
                                <span className="label-text capitalize">Your detail Address</span>
                            </label>
                            <textarea onChange={(e) => setAllAddress(e.target.value)} defaultValue={customerInfo?.address?.detail || ''} className="textarea textarea-bordered h-24 capitalize" name='detail' placeholder="Your detail Address"></textarea>
                        </div>

                        <div className='flex justify-end mt-6'>
                            <div className='flex justify-end'>
                                {
                                    spinner ? <ButtonSpinner></ButtonSpinner> : <button className='btn btn-primary'>Save Address</button>
                                }


                            </div>
                            <div className="ml-4">
                                <label htmlFor="address-update-modal" className="btn btn-warning">Cancel</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddressUpdate;