const addToWishLocal = (id) => {
    let shoppingCart = getShoppingWishList();
    const quantity = shoppingCart[id];
    if (quantity) {
        const newQuantity = quantity + 1;
        shoppingCart[id] = newQuantity;
    }
    else {
        shoppingCart[id] = 1;
    }


    localStorage.setItem('shopping-WishList', JSON.stringify(shoppingCart));
};



const getShoppingWishList = () => {
    let shoppingCart = {};

    const localStore = localStorage.getItem('shopping-WishList');
    if (localStore) {
        shoppingCart = JSON.parse(localStore);
    }

    return shoppingCart;
};


const deleteItemByIDWishList = (id) => {
    const storedCart = localStorage.getItem('shopping-WishList');
    if (storedCart) {
        const shoppingCart = JSON.parse(storedCart);
        if (id in shoppingCart) {
            delete shoppingCart[id];
            localStorage.setItem('shopping-WishList', JSON.stringify(shoppingCart));
        }
    }
};


const deleteLocalStoreWhishList = () => {
    localStorage.removeItem('shopping-WishList');
}



// const increaseQuantity = (id, inputQuantity) => {
//     let shoppingCart = getShoppingWishList();
//     const quantity = shoppingCart[id];
//     if (quantity) {
//         const newQuantity = parseInt(inputQuantity);
//         shoppingCart[id] = newQuantity;
//     }
//     else {
//         shoppingCart[id] = 1;
//     }


//     localStorage.setItem('shopping-WishList', JSON.stringify(shoppingCart));
// }

export { addToWishLocal, getShoppingWishList, deleteItemByIDWishList, deleteLocalStoreWhishList };