import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Cancel = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className="px-2 md:px-12 py-10">
            <Helmet>
                <title>Shop In Shop | Order cancelation policy </title>
            </Helmet>
            <div className="text-center text-3xl font-bold">
                Order cancelation policy {" "}
            </div>
            {/* <p className='text-xl font-bold my-2'>Return & Refund Policy  </p> */}
            <p className="text-sm ml-6 font-bold my-2">Order cancelation policy: </p>
            <ul className="ml-10">
                <li className="list-disc mb-2">
                    If you put order then you have a option to cancel for a limited time and this time is within 5 hours after putting your order. I that case if you do any payment the we will refund within 2-3 working days.{" "}
                </li>
                <li className="list-disc mb-2">
                    If your order is in “Processing” status, kindly wait for our delivery team to contact you. After getting their call you can ask them to cancel your order. An order with shipped status can’t be canceled from your end.
                </li>
                <li className="list-disc mb-2">
                    If you are facing trouble with cancelling your order or are not able to cancel the order on your own, kindly contact with us through email.
                </li>

            </ul>


        </div>
    );
};

export default Cancel;