import React from "react";
import notFoundImg from '../../Images/other_image/notFound.png';
const NotFound = () => {
  return (
    <div className="bg-[#F8F8F8] flex justify-center py-20">
      <img src={notFoundImg} alt="Page not found" />
    </div>
  );
};

export default NotFound;
