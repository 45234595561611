
import { useQuery } from "react-query";
const useCustomerInfo = email => {

    const { isLoading, data, refetch } = useQuery(['customerInfo', email], () =>
        fetch(`https://api.shopinshop.com.bd/customer/info/${email}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => res.json())
    );


    return [data, isLoading, refetch]
}

export default useCustomerInfo;