import React from "react";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { useMedia } from "react-use-media";
import "./ProductCart.css";

const ProductCart = ({
  product,
  handelWhishList,
  handelSingleProduct,
  handelCart,
  isMobile,
  className,
}) => {
  const mobile = useMedia("(max-width: 600px)");
  return (
    <div
      key={product._id}
      className={`card card-compact bg-base-100
      max-h-[460px]
      shadow-xl ${className && className
        }`}
    >
      <figure className="relative">
        <div
          className={
            isMobile
              ? " h-[100%] overflow-hidden w-full"

              : "h-[280px] flex justify-center p-2 overflow-hidden w-full"
          }
        >
          <Link to={`/singleProduct/${product._id}`}>
            <img
              className="transition ease-in-out delay-150 p-3 cursor-pointer hover:-translate-y-1 hover:scale-110 duration-300"
              src={`https://api.shopinshop.com.bd/${product.primaryImage}`}
              alt={product.productName}
              width="280"
              height="280"
            />
          </Link>
        </div>

        {product.sPrice && (
          <p className="absolute top-2 left-2 text-white text-sm bg-[#2BA968] rounded-full p-1">
            -
            {Math.round(
              parseFloat(
                (100 * (product.price - product.sPrice)) / product.price
              ).toFixed(2)
            )}
            %
          </p>
        )}
      </figure>
      <div className="card-body">
        <Link
          to={`/singleProduct/${product._id}`}
          className="flex justify-center mb-0"
        >
          {" "}
          <h2
            title={product.productName}
            className={
              isMobile
                ? "hover:text-orange-500 card-title flex cursor-pointer text-[.5rem] mx-auto"
                : "hover:text-orange-500 card-title cursor-pointer text-[.8rem] mx-auto"
            }
          >
            {product?.productName.length > 30 && !mobile
              ? product?.productName.slice(0, 30) + "...."
              : product?.productName.length > 20 && mobile
                ? product?.productName.slice(0, 20) + "...."
                : product.productName}
          </h2>
        </Link>

        <div className="rating rating-xs mx-auto">
          <StarRatings
            rating={product.avgRating || 0}
            starRatedColor="orange"
            starDimension="20px"
            starSpacing="1px"
          />
        </div>

        {product?.sPrice ? (
          <div className="text-center">
            <p>
              {" "}
              <span className="line-through">
                {Math.round(product.price)} &#2547;
              </span>{" "}
              <span className="text-lg font-bold text-[.9rem]">
                {Math.round(product.sPrice)} &#2547;
              </span>{" "}
            </p>
          </div>
        ) : (
          <div className="text-center">
            <p>
              <span className="text-lg font-bold text-[.9rem]">
                {Math.round(product.price)} &#2547;
              </span>{" "}
            </p>
          </div>
        )}

        <div className="card-actions justify-evenly items-center mt-2">
          <i
            onClick={() => handelWhishList(product)}
            className="hover:-translate-y-1 hover:scale-110 duration-300 transition ease-in-out delay-150  text-orange-500 cursor-pointer far fa-heart text-xl "
          ></i>

          <button
            onClick={() => handelSingleProduct(product._id)}
            className={
              isMobile
                ? "btn btn-primary  btn-sm text-[.5rem]"
                : "btn btn-primary  btn-sm text-[.7rem]"
            }
          >
            Buy Now
          </button>
          <i
            onClick={() => {
              handelCart(product);
            }}
            className="text-xl  hover:-translate-y-1 hover:scale-110 duration-300 transition ease-in-out delay-150  text-orange-500 cursor-pointer fas fa-cart-arrow-down"
          ></i>
        </div>
      </div>
    </div>
  );
};

export default ProductCart;
