import { signOut } from "firebase/auth";
import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet } from "react-helmet-async";
import { Link, Outlet, useNavigate } from "react-router-dom";
import useCustomerInfo from "../../Hooks/useCustomerInfo";
import Spinner from "../../Shared/Spinner/Spinner";
import auth from "../../firebase.init";


const Profile = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


  const [user, loading] = useAuthState(auth);
  const [customerInfo] = useCustomerInfo(user?.email);
  const navigate = useNavigate();

  const handelUploadProfile = (event) => {
    event.preventDefault();
    const img = event.target.img.value;
    // console.log(img);
  };

  if (loading) {
    return <Spinner></Spinner>
  }

  return (
    <div className="container mx-auto md:px-0 px-12">
      <Helmet>
        <title>Shop In Shop | {customerInfo?.name || "Profile"}</title>
      </Helmet>
      <div className="navbar bg-base-100">
        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex="0" className="btn btn-ghost btn-circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </label>
            <ul
              tabIndex="0"
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <Link to="order">Orders</Link>
              </li>
              <li>
                <Link to="/viewCart">Cart</Link>
              </li>
              <li>
                <Link to="address">Addresses</Link>
              </li>
              <li>
                <Link to="/wishList">Wishlist</Link>
              </li>
              <li>
                <label htmlFor="my-modal-3">Update Profile</label>
              </li>
              <li>
                <button
                  onClick={() => {
                    signOut(auth);
                    localStorage.removeItem("accessToken");
                    navigate("/");
                  }}
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-center">
          <Link to="/profile" className="font-bold normal-case text-xl">
            Hi {customerInfo?.name}
          </Link>
        </div>
        <div className="navbar-end">
          <button className="btn btn-ghost">
            <div className="avatar">
              <div className="w-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <Link to="/profile">
                  {" "}
                  {customerInfo?.img ? (
                    <img src={customerInfo?.img} alt="profile images" />
                  ) : (
                    <i className="fas fa-user"></i>
                  )}
                </Link>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div>
        <Outlet />
      </div>

      {/* modal */}

      <input type="checkbox" id="my-modal-3" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold text-center">Update Your Profile</h3>
          <form onSubmit={handelUploadProfile}>
            <div className="flex items-center space-x-6">
              <div className="shrink-0 my-6">
                {customerInfo?.img ? (
                  <img
                    className="h-16 w-16 object-cover rounded-full"
                    src={customerInfo?.img}
                    alt="Current profile photos"
                  />
                ) : (
                  <i className="fas fa-user"></i>
                )}
              </div>
              <label className="block">
                <span className="sr-only">Choose profile photo</span>
                <input
                  name="img"
                  type="file"
                  className="block w-full text-sm text-slate-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-full file:border-0
      file:text-sm file:font-semibold
      file:bg-violet-50 file:text-violet-700
      hover:file:bg-violet-100
    "
                  required
                />
              </label>
            </div>
            <input
              type="button"
              placeholder="Type here"
              className="border p-2 rounded-full outline-none input-bordered  w-full mb-4"
              value={user.displayName}
              readOnly
            />
            <input
              type="button"
              placeholder="Type here"
              className="border p-2 rounded-full outline-none input-bordered  w-full mb-4"
              value={user.email}
              readOnly
            />
            <input
              type="text"
              placeholder="Your phone Number"
              className="input rounded-full input-bordered input-warning w-full"
              value={user?.phoneNumber}
              required
            />
            <button
              type="submit"
              className="my-4 w-full bg-secondary rounded-full btn"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
