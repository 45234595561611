import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useProducts from "../../Hooks/useProducts";
import { useWishLish } from '../../Hooks/useWishList';
import Spinner from "../../Shared/Spinner/Spinner";
import { deleteItemByIDWishList } from "../../Utilitis/AddtoWishList";
import WishListProduct from "./WishListProduct";
const WishList = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const [data, isLoading, setCurrentPage, currentPage, refetch] = useProducts();
  const [whishList] = useWishLish(data);
  // console.log(whishList)
  if (isLoading) {
    return <Spinner></Spinner>
  }
  const handelDeleteWishList = id => {
    deleteItemByIDWishList(id);
    refetch();
  };

  return (
    <div className="px-12 h-screen my-4">
      <Helmet>
        <title>Shop In Shop | Wish List</title>
      </Helmet>
      <div class="overflow-x-auto w-full">
        <table class="table w-full">
          <thead>
            <tr>
              <th>Name</th>
              <th>Shop Name</th>
              <th>Price</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {whishList?.map((product) => (
              <WishListProduct
                handelDeleteWishList={handelDeleteWishList}
                key={product._id}
                product={product}
              ></WishListProduct>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WishList;
