import React, { useEffect, useRef, useState } from "react";
import useCategory from "../../Hooks/useCategory";
import MegaMenu from "../MegaMenu/MegaMenu";
import Spinner from "../Spinner/Spinner";
import "./Header.css";
import MobileMenu from "./MobileMenu/MobileMenu";
import TopHeader from "./TopHeader";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [toggelCart, setToggelCart] = useState(false);
  const [toggelProfile, setToggelProfile] = useState(false);
  const ref = useRef();
  const [search, setSearch] = useState('');
  const [searchProduct, setSearchProduct] = useState([]);
  const [data, isLoading] = useCategory();


  useEffect(() => {
    fetch(`https://api.shopinshop.com.bd/product/search/public?name=${search}`)
      .then(res => res.json())
      .then(result => {
        setSearchProduct(result)
      })
  }, [search])
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (mobileMenu && ref.current && !ref.current.contains(e.target)) {
        setMobileMenu(false);
      }
      if (toggelCart && ref.current && !ref.current.contains(e.target)) {
        setToggelCart(false);
      }
      if (toggelProfile && ref.current && !ref.current.contains(e.target)) {
        setToggelProfile(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [mobileMenu, toggelCart, toggelProfile]);


  if (isLoading) {
    return <Spinner></Spinner>
  }
  return (
    <header className="sticky top-0 z-[999]" ref={ref}>
      <TopHeader
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
        toggelCart={toggelCart}
        setToggelCart={setToggelCart}
        toggelProfile={toggelProfile}
        setToggelProfile={setToggelProfile}
        setSearch={setSearch}
        search={search}
        searchProduct={searchProduct}
      ></TopHeader>
      <MegaMenu key={data._id} data={data}></MegaMenu>
      <div
        className={`fixed top-[75px] left-0 ease-in-out duration-300 z-[9999999] overflow-y-auto pb-6 h-[600px] ${mobileMenu ? "ml-0" : "ml-[-500px] "
          }`}
      >
        <MobileMenu
          data={data}
          setSearch={setSearch}
          search={search}
          setSearchProduct={setSearchProduct}
          searchProduct={searchProduct}
          setMobileMenu={setMobileMenu}
        ></MobileMenu>
      </div>
    </header>
  );
};

export default Header;
