import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import AboutUS from '../../Images/other_image/about_us.png';
const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
      <div className="container mx-auto px-12 md:px-0 h-screen mt-10  md:mt-28">
        <Helmet>
          <title>Shop In Shop | About SIS</title>
        </Helmet>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <img src={AboutUS} alt="About us" />
          </div>

          <div>
            <h2 className="text-orange-500 font-bold text-2xl italic text-center underline">
              About Us
            </h2>
            <p className="mt-4">
              Shop in shop Bangladesh or{" "}
              <a
                className="text-orange-500"
                href="https://www.shopinshop.com.bd/"
              >
                www.shopinshop.com.bd
              </a>{" "}
              , a registered trade name and is the most trusted online Shopping
              platform in Bangladesh. Our journey started from September 27,
              2021. We take both pre-orders & stock product’s orders from our
              customers. We are providing all kinds of Indian authentic products
              in an affordable price like Jewelry, Beauty & Fashion, Home Décor,
              Festival Décor, Gift Items, and many more to the people of
              Bangladesh door to door. Shop in Shop Bangladesh (SIS) is
              providing an excellent customer experience, comprehensive customer
              care, providing best discount campaigns to the customers to make
              happy and also providing return & refund services. We are allowing
              others authentic registered sellers (National/International) to
              sale their products through our platform. Our goal is to advance
              customer’s trust and confidence as the key to success.
            </p>

            <div className="w-[80%] mx-auto">
              <p className="text-md font-bold my-6">
                Shop in Shop Trade license Number and TIN Certificate Number:{" "}
              </p>
              <ul className="ml-10">
                <li className="list-disc mb-2">
                  <b>Trade license Number : </b> 179817
                </li>
                <li className="list-disc mb-2">
                  <b>TIN Certificate Number : </b> 274930684704
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
};

export default About;