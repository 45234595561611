import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const ProductButton = () => {
    const initiateMessengerChat = () => {
        // Create a predefined message
        const message = {
            text: "I wanted to know more about this product: [Product Link]",
        };

        // Trigger the Messenger chat
        window.FB.CustomerChat.show(true);
        window.FB.CustomerChat.update({
            logged_in_greeting: 'Hello! How can we assist you?',
            ...message,
        });
    };

    const productLink = window.location.href;
    // console.log(productLink)

    const phoneNumber = "+8801778057784";

    const openWhatsAppChat = () => {
        window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=Check%20out%20this%20amazing%20product:%20${encodeURIComponent(productLink)}`);
    };


    return (
        <>

            <p className="uppercase font-bold">Instant Chat For This Product: <i className="mx-2 text-[20px] hover:text-orange-500 fab fa-facebook-messenger"
                onClick={() => initiateMessengerChat()}
            ></i>
                <i className="text-[20px] hover:text-orange-500  fab fa fa-whatsapp" aria-hidden="true"
                    onClick={openWhatsAppChat}
                ></i>


            </p>
            <MessengerCustomerChat pageId="879856998719807" appId="2314411935411637" />
        </>
    );
};

export default ProductButton;