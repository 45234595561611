import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import bPrivacyImg from '../../Images/other_image/privacy_policy.png';
const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
      <div className="container mx-auto px-12 md:px-0">
        <Helmet>
          <title>Shop In Shop | Privacy Policy</title>
        </Helmet>
        <div>
          <div className="flex justify-center mt-2">
            <img
              className="w-full h-60"
              src={bPrivacyImg}
              alt="Privicy Policy"
            />
          </div>
          <div className="px-10 mt-6">
            <p>
              Welcome to Shopinshop.Com.bd best online shopping platform in
              Bangladesh. This Privacy Policy explains how we collect, use and
              disclose your personal information. Your privacy is very important
              to us, so we respect your privacy and we want to protect it. Only
              we will use your name and other information which related to your
              manner set out in this Privacy Policy. We will collect information
              where it is necessary for our dealings with you. We will reserve
              your information as long as we need it. You can visit our site
              without provide personal details. During visit the site you remain
              anonymous, that’s moment we can identify you. Unless you have an
              account on the site and log on your account. The user has read our
              privacy policy and he understood it. The user also acknowledges
              that this privacy policy is acceptable to him/her.
            </p>

            <div>
              <h2 className="text-lg font-bold text-primary my-6">
                Your Information Collect Procedure:
              </h2>
              <p>
                We may collect various type of information if you request to
                place an order for a product. Your purchasing time we collects
                your information. For further inquiry we can verify your claim.
                SIS can collect personal information, including name, gender,
                date of birth, email address, postal address, delivery address
                (if different), telephone number, mobile number, payment
                details.
              </p>
            </div>
            <div>
              <h2 className="text-lg font-bold text-primary my-6">
                Termination:
              </h2>
              <li>
                Shopinshop.com.bd may suspend or terminate your use of the
                website or any service if it believes, in its sole and absolute
                judgment that you have violate any of the Terms.
              </li>
              <li>
                If you or ShopinShop.Com.bd terminates your use of the website
                or any service, SIS may delete any content or other materials
                relating to your use of the service. We have not liability to
                you or any third party for doing so.
              </li>
              <li>
                You shall be liable to pay for any service or product that you
                have already ordered till the time of termination by other
                party. Further, you shall be entitled to your royalty payments
                as per the User License Agreement that has or is legally deemed
                accrued to you
              </li>
            </div>
            <div>
              <h2 className="text-lg font-bold text-primary my-6">
                Links to Third Party Sites:
              </h2>
              <p>
                The Website may contain links to other websites (“Linked
                Sites”). The linked sites are not under the control of
                Shopinshop.com.bd or the website. Shopinshop.Com.bd is not
                responsible for the contents of any linked site, including
                without limitation any link contained in a linked site, or any
                changes or updates to a linked site. JadRoo.Com is not
                responsible for any kind of transmission, whatsoever, received
                by you from any linked site. Shopinshop.Com.bd is providing
                these links to you only as a convenience, the inclusion of any
                link does not imply endorsement by Shopinshop.Com.bd or the
                website of the linked sites or any association with its
                operators or owners including the legal representative or
                assigns thereof. The users are requested to verify the accuracy
                of all information on their own before undertaking any reliance
                on the information.
              </p>
            </div>
            <div>
              <h2 className="text-lg font-bold text-primary my-6">
                Governing Law:
              </h2>
              <p>
                These terms shall be governed and constructed in accordance with
                the laws of Bangladesh without reference to conflict of laws
                principles and disputes arising in relation. The subject shall
                be exclusive jurisdiction of the courts at Dhaka, Bangladesh.
              </p>
            </div>
            <div>
              <h2 className="text-lg font-bold text-primary my-6">
                Protection:
              </h2>
              <p>
                We have in place adequate technical and security measures to
                prevent unauthorized or illegal access to or accidental loss of
                or destruction or damage to your information. When we collect
                data through the site, we collect your personal details on a
                secure server. We use firewalls on our servers. Our security
                procedures mean that we may occasionally request proof of
                identity before we expose personal information to you. You are
                responsible for protecting against unauthorized access to your
                password and to your computer.
              </p>
            </div>
            <div className="pb-10">
              <h2 className="text-lg font-bold text-primary my-6">
                User Warranty and Representation:
              </h2>
              <p>
                The user guarantees, warrants, and certifies that you are the
                owner of the content which you submit otherwise authorized to
                use the content. The content does not infringe upon the property
                rights, intellectual property rights of others. Further warrant
                that to your knowledge, action, case, proceeding or
                investigation has been institute or threatened relating to any
                content, including trademark, trade name service mark, and
                copyright formerly or currently used by you in connection with
                the services done by Shopinshop.com.bd
              </p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Privacy;