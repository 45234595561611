import { useEffect, useState } from "react";

const Blog = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        // Make an API request to fetch the blog posts from the MongoDB API
        fetch("https://api.shopinshop.com.bd/posts")
            .then((response) => response.json())
            .then((data) => {
                // console.log(data)
                setPosts(data)
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <div className="blog-landing">
            {/* <h1>Blog Posts</h1>
            {posts?.map((post) => (
                <div key={post.id} className="blog-post">
                    <h2>{post.title}</h2>
                    <p>{post.content}</p>
                    <p>Author: {post.author}</p>
                    <p>Date: {post.date}</p>
                    <h3>Comments:</h3>
                    <ul>
                        {post.comments.map((comment) => (
                            <li key={comment.id}>
                                <strong>{comment.author}:</strong> {comment.text}
                            </li>
                        ))}
                    </ul>
                </div>
            ))} */}
            <h2 className="text-center">Blog page coming soon...</h2>
        </div>
    );
};

export default Blog;
