import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useMedia } from "react-use-media";
import MenuItem from "./MenuItem";

const MegaMenu = ({ data }) => {
  const isMobile = useMedia("(max-width: 700px)");
  const [showAllCategories, setShowAllCategories] = useState(false);

  // Function to toggle the "See More" button
  const toggleCategories = () => {
    setShowAllCategories(!showAllCategories);
  };

  return (
    <div className="bg-secondary py-[10px] md:block megaMenu">
      <div className="px-5 md:px-10 mx-auto text-white">
        <ul className="justify-center xl:justify-between flex flex-wrap gap-2">
          {isMobile
            ? (showAllCategories ? data : data.slice(0, 5)).map((category, index) => (
              <MenuItem
                key={category._id}
                category={category}
                index={index}
              />
            ))
            : data?.map((category, index) => (
              <MenuItem
                key={category._id}
                category={category}
                index={index}
              />)
            )}

          {isMobile && data.length > 5 && (
            <button onClick={toggleCategories}>
              {showAllCategories ? "See Less" : "See More"}
            </button>
          )}
        </ul>
      </div>
    </div>
  );
};

export default MegaMenu;
