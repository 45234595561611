import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className="w-[80%] mx-auto my-10 px-12">
      <Helmet>
        <title>Shop In Shop | FAQS</title>
      </Helmet>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>
            1. Is it necessary to have an account to shop on Shop in
            Shop(SIS)?
          </p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            Yes, it's necessary to log into your shopinshop account to shop.
            Shopping as a logged-in user is fast & convenient and also
            provides extra security.
          </p>
        </div>
      </div>

      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p> 2. How can I open an account?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            Press the login icon "Register now" Input your Name, Phone Number,
            Email & Set a password. Click create an account to complete the
            registration
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>3. How can I place an order?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            To place an order, please follow these steps:
            <br />
            i. Select the product you'd like to buy and 'Check Availability
            at' your preferred pin code
            <br />
            ii. Add products to your cart or just hit 'Buy Now'
            <br />
            iii. Choose or 'Add delivery address'. Use a preferred payment
            mode and confirm the order.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>4. How do I know my order has been confirmed?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            Confirmation call/An e-mail/SMS/ will be sent once you've
            successfully placed your order. We'll also let you know as soon as
            the seller ships the item(s) to you along with the tracking
            number(s) for your shipment(s). You can track your orders from the{" "}
            <a
              className="text-orange-500"
              href="shop.com.bd/profile/order"
            >
              'My Orders'
            </a>{" "}
            section.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>5. What is the estimated delivery time?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            Min 2 hours & Max 24 hours Inside Dhaka. (Depends on Order Time)
            <br />
            Within 72 hours outside Dhaka.
            <br />
            You can check the expected delivery date of your order(s) on the
            'My Orders' section of your account on our website/App.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>6. How Can I Pay?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            Currently we are offering "Cash on delivery" and Online payment
            options.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>7. What is cash on delivery?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            Cash on Delivery is a mode of payment in which you can pay cash at
            the time of delivery of your order.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>8. Is delivery charge applicable? What will be the charge?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            Yes, delivery charge is applicable. Charge will be variable
            depends on Locations/Products.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>9. What if I want to change my delivery address? </p>
        </div>
        <div className="collapse-content text-primary">
          <p>Please let us know within an hour after the order is placed.</p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>10. What is your policy on Return & Refunds? </p>
        </div>
        <div className="collapse-content text-primary">
          <a href="https://shopinshop.com.bd/shipping" target="blank">
            Please review our{" "}
            <span className="text-orange-500 hover:underline">
              {" "}
              Return & Refund policy
            </span>
            .
          </a>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>11. Can I cancel/change my order? </p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            To cancel/change any online order, order cancellation must be
            requested within 15 minutes after order placement.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>12. Why do I see different prices for the same product?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            You could see different prices for the same product, as it could
            be listed by many Sellers.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>13. Is installation offered for all products?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            Please check the individual product page to see if these services
            are offered for the item.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>14. Do sellers of Shop In Shop's ship nationally?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>Yes, sellers of Shop In Shop anywhere in Bangladesh.</p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>
            15. Can I order a product that is 'Out of Stock' or 'Temporarily
            Unavailable'?
          </p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            The products listed as 'Out of Stock' or 'Temporarily Unavailable'
            are not available for sale. Please use the 'Contact us' feature to
            be informed of the product's availability with sellers on Shop In
            Shop.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>16. How are items packaged?</p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            Standard packing procedure will be maintained by Seller. Sellers
            are rated on packaging quality and affects overall seller rating.
          </p>
        </div>
      </div>
      <div
        tabIndex="0"
        className="collapse collapse-plus bg-slate-100 mb-4 rounded-box"
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-lg font-semibold">
          <p>
            17. If I missed to receive the delivery of my order today. What
            should I do?
          </p>
        </div>
        <div className="collapse-content text-primary">
          <p>
            The courier service delivering your order usually tries to deliver
            on the next business day in case you miss a delivery. You can
            check your SMS for more details on when the courier service will
            try to deliver again.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faqs;