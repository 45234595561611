import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ButtonSpinner from '../../Shared/ButtonSpinner';
const Review = ({ setReviewModal, reviewModal }) => {
    const [loading, setLoading] = useState(false);
    const handelReview = (event) => {
        event.preventDefault();
        setLoading(true);
        const rating = parseInt(event.target.rating.value) || 5;
        const comment = event.target.comment.value;

        const review = { rating, comment, reviewModal }
        fetch(`https://api.shopinshop.com.bd/order/review/customer`, {
            method: "POST",
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify(review)

        })
            .then(res => res.json())
            .then(data => {
                if (data.acknowledged) {
                    toast("Thanks for your valuable feedback");
                    setReviewModal(null);
                    setLoading(false)
                }

            })
    }
    return (
        <div>

            <input type="checkbox" id="review" class="modal-toggle" />
            <div class="modal modal-bottom sm:modal-middle">
                <div class="modal-box">

                    <div className=''>
                        <div className='px-12'>
                            <form onSubmit={handelReview}>
                                <div class="rating flex justify-center  mb-4">
                                    <input type="radio" value='1' name="rating" class="mask mask-star-2 bg-orange-400" />
                                    <input type="radio" value='2' name="rating" class="mask mask-star-2 bg-orange-400" />
                                    <input type="radio" value='3' name="rating" class="mask mask-star-2 bg-orange-400" />
                                    <input type="radio" value='4' name="rating" class="mask mask-star-2 bg-orange-400" />
                                    <input type="radio" value='5' name="rating" class="mask mask-star-2 bg-orange-400" />
                                </div>
                                <div>
                                    <textarea className="textarea textarea-warning w-full" name='comment' placeholder="Your Review" required></textarea>
                                </div>
                                <div className='flex justify-end'>
                                    {
                                        loading ? <div className='mr-4'><ButtonSpinner></ButtonSpinner> </div> : <button className='btn btn-primary mr-4'>Submit</button>
                                    }
                                    <label htmlFor="review" class="btn bg-orange-500">Cancel</label>
                                </div>

                            </form>
                        </div>
                    </div>


                </div>
            </div>
        </div>



    );
};

export default Review;