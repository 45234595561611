import React from "react";

const HeroSection = () => {
  return (
    <div className="hidden px-5 md:px-10  md:grid grid-cols-1  md:grid-cols-3 gap-4 mx-auto ">
      <div className="flex items-center justify-around p-4 my-6 border-2 md:border-r-2 md:border-0">
        <i className="text-4xl fa-solid fa-headset"></i>
        <div>
          <h2 className="text-[14px] font-bold ">ONLINE SUPPORT</h2>
          <p>Online dedicated whatsapp support</p>
        </div>
      </div>
      <div className="flex items-center justify-around p-4 my-6 border-2 md:border-r-2 md:border-0">
        <i className="text-4xl fa-solid fa-money-bill-transfer"></i>
        <div>
          <h2 className="text-[14px] font-bold ">MONEY BACK GUARANTEE</h2>
          <p>100% money back guarantee</p>
        </div>
      </div>
      <div className="flex items-center justify-around p-4 my-6 border-2 md:border-none">
        <i className="text-4xl fa-solid fa-truck-fast"></i>
        <div>
          <h2 className="text-[14px] font-bold ">FREE SHIPPING & RETURN</h2>
          <p>Free shipping on all orders over &#x09F3;5000</p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
