import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use-media';
import { cartContext } from '../../App';
import Spinner from '../../Shared/Spinner/Spinner';
import { addToWishLocal } from '../../Utilitis/AddtoWishList';
import { addToCartLocal } from '../../Utilitis/addToCartLocal';
import MobileProductCart from '../Cart/MobileProductCart';
import ProductCart from '../Cart/ProductCart';
import Pagination from '../Pagination/Pagination';

const AllProducts = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const carts = useContext(cartContext);
  const [cart, setCartValue, setCart, whishList, setWhishList] = carts;
  const navigate = useNavigate();
  const extraSmall = useMedia("(max-width : 450px)");
  const isMobile = useMedia("(max-width: 700px)");
  const tab = useMedia("(min-width: 700px) and (max-width: 1000px)");
  const largePc = useMedia("(min-width: 1000px) and (min-width: 1800px)");

  useEffect(() => {
    setCartValue(data);
  }, [data]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalItem, setTotalItem] = useState(0);


  // console.log(data)

  useEffect(() => {
    setIsloading(true);
    fetch(`https://api.shopinshop.com.bd/allproduct/client/public?page=${currentPage - 1}`)
      .then(res => res.json())
      .then(result => {
        setData(result);
        setIsloading(false);
      })
  }, [currentPage]);

  useEffect(() => {
    setIsloading(true);
    fetch(`https://api.shopinshop.com.bd/product/client/public/count`)
      .then(res => res.json())
      .then(result => {
        const count = result.count;
        const pages = Math.ceil(parseInt(count) / 20);

        setPageCount(pages);
        setTotalItem(count);
        setIsloading(false);
      })
  }, [])




  if (isLoading) {
    return <Spinner></Spinner>
  }
  const handelSingleProduct = id => {
    navigate(`/singleProduct/${id}`)
  };


  const handelWhishList = (product) => {
    let newWishList = [];
    const exists = whishList.find(cartProduct => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newWishList = [...whishList, product];
    }
    else {
      const rest = whishList.filter(cartProduct => cartProduct._id !== product._id);
      product.quantity += 1;
      newWishList = [...rest, exists];
    }

    if (!isLoading) {
      setWhishList(newWishList);
      addToWishLocal(product._id);
    }

  }



  const handelCart = (product) => {

    let newCart = [];
    const exists = cart.find(cartProduct => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newCart = [...cart, product];
    }
    else {
      const rest = cart.filter(cartProduct => cartProduct._id !== product._id);
      product.quantity += 1;
      newCart = [...rest, exists];
    }

    if (!isLoading) {
      setCart(newCart);
      addToCartLocal(product._id);
    }

  }

  return (
    <div>
      <Helmet>
        <title>Shop In Shop | All Product</title>
      </Helmet>
      <div
        className={`gap-5 mb-10 grid px-5 justify-center ${isMobile
          ? "grid-cols-2"
          : largePc
            ? "grid-cols-6"
            : tab
              ? "grid-cols-3"
              : "grid-cols-4"
          }`}
      >
        {data
          ?.map((product) =>
            extraSmall ? (
              <MobileProductCart
                product={product}
                handelWhishList={handelWhishList}
                handelSingleProduct={handelSingleProduct}
                handelCart={handelCart}
                isMobile={isMobile}
              ></MobileProductCart>
            ) : (
              <ProductCart
                product={product}
                handelWhishList={handelWhishList}
                handelSingleProduct={handelSingleProduct}
                handelCart={handelCart}
                isMobile={isMobile}
              ></ProductCart>
            )
          )
          .reverse()}
      </div>

      {totalItem > 20 && (
        <div className="flex justify-center">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
          ></Pagination>
        </div>
      )}
    </div>
  );
};

export default AllProducts;