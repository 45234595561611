import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import useCustomerInfo from "../../Hooks/useCustomerInfo";
import Spinner from "../../Shared/Spinner/Spinner";
import auth from "../../firebase.init";
import Pagination from "../Pagination/Pagination";
import SingleOrder from "./SingleOrder";

const Orders = () => {
  // const [orders, setOrder] = useState([]);
  const [isLoadings, setLoading] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [customerInfo] = useCustomerInfo(user?.email);
  const [pageCount, setPageCount] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, data, refetch } = useQuery(
    ["orders", currentPage, customerInfo],
    () =>
      fetch(
        `https://api.shopinshop.com.bd/orders/${customerInfo?.email}?page=${currentPage - 1
        }`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      ).then((res) => res.json())
  );
  // (data)
  useEffect(() => {
    setLoading(true);
    fetch(
      `https://api.shopinshop.com.bd/orders/count/${customerInfo?.email}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const count = result.count;
        const pages = Math.ceil(parseInt(count) / 10);
        (pages);
        setPageCount(pages);
        setTotalItem(count);
        setLoading(false);
      });
  }, [customerInfo]);
  if (loading || isLoading || isLoadings) {
    return <Spinner></Spinner>;
  }

  return (
    <div>
      <Helmet>
        <title>Shop In Shop | Orders</title>
      </Helmet>

      <div className="overflow-x-auto w-full">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>TOtal Price</th>
              <th>Date</th>
              <th>Status</th>
              <th>Payment Status</th>
              <th>Download Invoice</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((order) => (
              <SingleOrder
                key={order._id}
                refetch={refetch}
                order={order}
              ></SingleOrder>
            ))}
          </tbody>
        </table>
      </div>

      <div className="my-6">
        {totalItem > 10 && (
          <div className="flex justify-center">
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageCount={pageCount}
            ></Pagination>
          </div>
        )}
      </div>
    </div>
  );
};

export default Orders;
