import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCart } from "../../Hooks/useCart";
import useCustomerInfo from "../../Hooks/useCustomerInfo";
import useProducts from "../../Hooks/useProducts";
import ButtonSpinner from "../../Shared/ButtonSpinner";
import Spinner from "../../Shared/Spinner/Spinner";
import {
  deleteItemByID,
  getShoppingCart,
  increaseQuantity
} from "../../Utilitis/addToCartLocal";
import auth from "../../firebase.init";
import AddressUpdate from "./AddressUpdate";

const ViewCart = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [paymentLoading, setPatmentLoading] = useState(false);
  const [paymentType, setPaymenttype] = useState("Pay");
  const [addressUpdate, setAddressUpdate] = useState(null);
  const navigation = useNavigate();
  const cartProduct = Object.keys(getShoppingCart());
  const [customerNotes, setCustomerNote] = useState("");
  const [polecy, setPolicy] = useState(false);
  // console.log(customerNote)

  const { isLoading, data, refetch } = useQuery(
    ["productCart", cartProduct],
    () =>
      fetch(
        `https://api.shopinshop.com.bd/products/cart?name=${cartProduct}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      ).then((res) => res.json())
  );

  const [datas, isLoadings] = useProducts();
  const [cart] = useCart(data);
  const [user, loading] = useAuthState(auth);
  const [customerInfo, isLoadingss, refetchss] = useCustomerInfo(user?.email);
  const [singleSate, setSingleState] = useState(customerInfo?.address?.state);
  const [singleCity, setSingleCity] = useState(customerInfo?.address?.city);
  const navigate = useNavigate();
  useEffect(() => {
    setSingleState(customerInfo?.address?.state);
    setSingleCity(customerInfo?.address?.city);
  }, [customerInfo]);

  // console.log(singleCity, singleSate)

  if (isLoading || isLoadings || loading || isLoadingss) {
    return <Spinner></Spinner>;
  }

  const handelCartItem = (id) => {
    deleteItemByID(id);
    refetch();
  };

  const handelQuantity = (id, quantity) => {
    increaseQuantity(id, quantity);
    refetch();
  };

  const productPrice = cart.reduce(
    (previousValue, currentValue) =>
      previousValue +
      parseInt(
        currentValue.sPrice
          ? currentValue.sPrice * currentValue.quantity
          : currentValue.price * currentValue.quantity
      ),
    0
  );
  const shippingCharge = cart.reduce(
    (previousValue, currentValue) =>
      previousValue +
      parseInt(
        singleSate === "Dhaka" && singleCity === "Dhaka"
          ? currentValue.deliveryInDhaka
          : currentValue.outDhaka
      ),
    0
  );
  // const vat = (productPrice + shippingCharge) * 0.15;
  const total = productPrice + shippingCharge;
  // const total = productPrice + shippingCharge + vat;
  // const preOrderProductPrice = cart.reduce((previousValue, currentValue) => previousValue + parseInt(currentValue.sPrice && currentValue.orderType === 'Pre-Order' ? currentValue.sPrice * currentValue.quantity : currentValue.price * currentValue.quantity), 0);
  let preOrderProductPrice = 0;
  for (let x of cart) {
    if (
      x.orderType === "Pre-Order" &&
      singleSate === "Dhaka" &&
      singleCity === "Dhaka"
    ) {
      preOrderProductPrice =
        preOrderProductPrice +
        x.sPrice * parseInt(x.quantity) +
        parseInt(x.deliveryInDhaka) ||
        x.price * parseInt(x.quantity) + parseInt(x.deliveryInDhaka);
    } else if (x.orderType === "Pre-Order") {
      preOrderProductPrice =
        preOrderProductPrice +
        x.sPrice * parseInt(x.quantity) +
        parseInt(x.outDhaka) ||
        x.price * parseInt(x.quantity) + parseInt(x.outDhaka);
    }
  }

  const parcentPrice = preOrderProductPrice * 0.25;
  // console.log(preOrderProductPrice)
  const totalPayable = cart.some((type) => type.orderType === "Pre-Order")
    ? parcentPrice
    : total;
  const due = cart.some((type) => type.orderType === "Pre-Order")
    ? total - parcentPrice
    : paymentType === "Pay"
      ? 0
      : total;

  // console.log(cart)

  const buyCartProduct = (event) => {
    event.preventDefault();
    const customerName = customerInfo?.name;
    const customerEmail = customerInfo.email;
    const customerPhone = event.target.phone.value;
    const customerState = customerInfo?.address?.state;
    const customerCity = customerInfo?.address?.city;
    const customerDetails = customerInfo?.address?.detail;
    const customerNote = customerNotes;
    const orderID = customerInfo?.address?.state + Date.now();

    const color = "";
    const size = "";
    const deliveryCharge = shippingCharge;
    const totalPrice = total;
    // const vatPrice = vat.toFixed(2);
    const duePrice = due;
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    const time = today.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    const products = [];

    for (let x of cart) {
      products.push({
        productId: x._id,
        productName: x.productName,
        productImage: x.primaryImage,
        quantity: x.quantity,
        deliveryCharge:
          singleSate === "Dhaka" && singleCity === "Dhaka"
            ? x.deliveryInDhaka
            : x.outDhaka,
        productPrices: x.sPrice ? x.sPrice : x.price,
        productTotalPrice: x.sPrice
          ? x.sPrice * x.quantity
          : x.price * x.quantity,
        orderType: x.orderType,
        status: "Pending",
        marchentEmail: x.marchentEmail,
        shopPhone: x.marchantPhone,
        shopName: x.marchentShop,
      });
    }

    // console.log(products)

    const orderInfo = {
      customerName,
      customerEmail,
      customerPhone,
      customerState,
      customerCity,
      customerDetails,
      customerNote,
      orderID,
      color,
      size,
      deliveryCharge,
      totalPrice,
      totalPayable,
      duePrice,
      isCoupon: false,
      date,
      time,
      status: "Pending",
      paymentDone: false,
      orderValue: "Cart",
      products,
    };
    // const orderInfo = { customerName, customerEmail, customerPhone, customerState, customerCity, customerDetails, customerNote, orderID, color, size, deliveryCharge, totalPrice, totalPayable, vatPrice, duePrice, isCoupon: false, date, time, status: 'Pending', paymentDone: false, orderValue: 'Cart', products };

    if (
      cart.some((type) => type.orderType === "Pre-Order") ||
      paymentType === "Pay"
    ) {
      const paymentInfo = { ...orderInfo, paymentMethod: "Pending" };
      setPatmentLoading(true);
      fetch(`https://api.shopinshop.com.bd/online-payment/public`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(paymentInfo),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.data) {
            window.location.replace(result.data);
            setPatmentLoading(false);
          } else {
            toast("Something is Wrong! Please Try Again");
            setPatmentLoading(false);
          }
        });
    } else {
      setPatmentLoading(true);
      const cashInfo = {
        ...orderInfo,
        paymentDone: false,
        paymentMethod: "Cash On Delivery",
        payment: "Due",
      };
      fetch(`https://api.shopinshop.com.bd/order/public`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(cashInfo),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.acknowledged) {
            toast("Your Order Place Successfully!!");
            setPatmentLoading(false);
            navigate(`/COD-orderSuccess/${cashInfo.orderID}`);
            localStorage.removeItem("shopping-cart");
          }
        });
    }
  };

  return (
    <div className="px-5 md:px-10 my-10">
      <Helmet>
        <title>Shop In Shop | Cart</title>
      </Helmet>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="col-span-2">
          <div className="overflow-x-auto w-full">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Option</th>
                </tr>
              </thead>
              <tbody>
                {cart?.map((product) => (
                  <tr key={product._id}>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div className="avatar">
                          <div className="mask mask-squircle w-12 h-12">
                            <img
                              src={`https://api.shopinshop.com.bd/${product.primaryImage}`}
                              alt="Avatar Tailwind CSS Component"
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        title={product.productName}
                        className="hover:text-orange-500 cursor-pointer"
                        onClick={() =>
                          navigation(`/singleProduct/${product._id}`)
                        }
                      >
                        {product?.productName.length > 30
                          ? product?.productName.slice(0, 20) + "...."
                          : product.productName}
                      </span>
                      <br />
                      <span className="badge p-2 text-orange-500 badge-xs">
                        {product.orderType}
                      </span>
                    </td>
                    <td>
                      <div className="flex items-center">
                        <input
                          onChange={(e) => {
                            handelQuantity(product._id, e.target.value);
                            refetch();
                          }}
                          onBlur={(e) => {
                            handelQuantity(product._id, e.target.value);
                            refetch();
                          }}
                          type="number"
                          placeholder="Quantity"
                          max={
                            datas?.find((p) => p._id === product._id)?.quantity
                          }
                          min="1"
                          defaultValue={product?.quantity}
                          className="w-[50%] input input-bordered  max-w-xs"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center">
                        <p>
                          {`${product.quantity} X ${product.sPrice || product.price
                            } = ${product.quantity * product.sPrice || product.price
                            }`}{" "}
                          &#2547;
                        </p>
                      </div>
                    </td>
                    <th>
                      <i
                        onClick={() => handelCartItem(product._id)}
                        className="cursor-pointer hover:text-orange-500 far fa-trash-alt"
                      ></i>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <hr />
          <div className="form-control mt-6">
            <label className="label">
              <span className="label-text">Special Note</span>
            </label>
            <textarea
              onChange={(e) => setCustomerNote(e.target.value)}
              className="textarea textarea-bordered h-24"
              name="note"
              placeholder="Special Note"
            ></textarea>
          </div>
        </div>

        <div>
          <form onSubmit={buyCartProduct}>
            <div className=" flex items-center justify-between">
              <div className="w-full">
                {singleSate && singleCity && customerInfo?.address?.detail ? (
                  <p
                    title={`${singleSate} , ${singleCity} , ${customerInfo?.address?.detail}`}
                    className="text-center bg-[#E6E6E6] rounded-full p-4"
                  >{`${singleSate} , ${singleCity} , ${customerInfo?.address?.detail.length > 20
                    ? customerInfo?.address?.detail.slice(0, 19) + "..."
                    : customerInfo?.address?.detail
                    }`}</p>
                ) : (
                  <p className="text-center bg-warning rounded-full p-4">
                    Please add your shipping address
                  </p>
                )}

                <div className="text-center text-sm">
                  <p className="text-orange-500">
                    {" "}
                    {cart.some((type) => type.orderType === "Pre-Order")
                      ? "Estimated Delivery 7-14 days"
                      : singleSate === "Dhaka" &&
                        singleCity === "Dhaka" &&
                        cart.some((type) => type.orderType === "COD")
                        ? "Estimated Delivery 3-5 days"
                        : "Estimated Delivery 5-7 days"}{" "}
                  </p>
                </div>
              </div>
              <div>
                <label
                  onClick={() => setAddressUpdate(customerInfo)}
                  htmlFor="address-update-modal"
                  className="btn btn-sm ml-2"
                >
                  Edit
                </label>
              </div>
            </div>

            <div className="justify-end">
              <div className="card m-0 md:m-4 bg-base-100 shadow-xl">
                <div className="form-control w-full mx-auto md:max-w-xs">
                  <label className="label">
                    <span className="label-text">Phone Number</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your Phone Number"
                    className="input input-bordered rounded-full w-full max-w-xs"
                    required
                  />
                </div>

                <div className="card-body items-center text-center">
                  {/* <div>
                                        <p className='text-orange-500'> {cart.some(type => type.orderType === 'Pre-Order') ? 'Estimated Delivery 7-14 days' : singleSate === 'Dhaka' && singleCity === "Dhaka" && cart.some(type => type.orderType === 'COD') ? 'Estimated Delivery 3-5 days' : 'Estimated Delivery 5-7 days'}  </p>
                                    </div> */}
                  <div className="flex w-full justify-between">
                    <p>
                      <b>Subtotal</b>{" "}
                    </p>
                    <p>{productPrice} &#2547;</p>
                  </div>
                  <div className="w-full">
                    <hr></hr>
                  </div>
                  <div className="flex w-full justify-between">
                    <p>
                      <b>Shipping</b>{" "}
                    </p>
                    <p>{shippingCharge} &#2547;</p>
                  </div>
                  {/* <div className='w-full '><hr></hr></div>

                                    <div className='flex w-full justify-between'>
                                        <p><b>Vat</b> </p>
                                        <p>{vat.toFixed(2)} &#2547;</p>
                                    </div> */}

                  <div className="w-full">
                    <hr></hr>
                  </div>

                  <div className="flex w-full justify-between">
                    <p>
                      <b>Total</b>{" "}
                    </p>
                    <p>{total.toFixed(2)} &#2547;</p>
                  </div>

                  <div className="w-full ">
                    <hr></hr>
                  </div>

                  <div className="flex w-full justify-between">
                    <p>
                      <b>Payable Total</b>{" "}
                    </p>
                    <p>
                      {" "}
                      <b>{totalPayable.toFixed(2)}</b> &#2547;
                    </p>
                  </div>
                  <div className="w-full">
                    <hr></hr>
                  </div>

                  {/* {
                                        cart.some(type => type.orderType === 'Pre-Order') || paymentType === 'Pay' ? <div>
                                            <div className='flex w-full justify-between'>
                                                <p><b>Total Due </b> </p>
                                                <p> <b> &nbsp;= {due.toFixed(2)}</b> &#2547;</p>
                                            </div>
                                            <div className='w-full'><hr></hr></div>
                                        </div> : ''
                                    } */}

                  <div>
                    <div className="flex w-full justify-between">
                      <p>
                        <b>Total Due </b>{" "}
                      </p>
                      <p>
                        {" "}
                        <b> &nbsp;= {total.toFixed(2)}</b> &#2547;
                      </p>
                    </div>
                    <div className="w-full">
                      <hr></hr>
                    </div>
                  </div>
                </div>

                {cart.some((type) => type.orderType === "Pre-Order") || (
                  <div className="flex justify-center mb-10">
                    <div className="form-control w-full max-w-xs">
                      <select
                        onChange={(e) => setPaymenttype(e.target.value)}
                        className="select select-bordered"
                      >
                        <option value="Pay">Payment Online</option>
                        <option value="COD">Cash on Delivery</option>
                      </select>
                    </div>
                  </div>
                )}

                {productPrice > 0 &&
                  singleSate &&
                  singleCity &&
                  customerInfo?.address?.detail && (
                    <>
                      <div className="form-control ml-6 mb-2">
                        <label className="flex items-center cursor-pointer">
                          <input
                            onChange={(e) => setPolicy(e.target.checked)}
                            type="checkbox"
                            className="checkbox"
                          />

                          <span className="label-text ml-4">
                            {" "}
                            Agree to the{" "}
                            <a
                              className="underline text-orange-500 hover:cursor-pointer"
                              href="/terms"
                              target="_new"
                            >
                              Terms & Conditions
                            </a>
                            ,{" "}
                            <a
                              className="underline text-orange-500 hover:cursor-pointer"
                              href="/privacy"
                              target="_new"
                            >
                              Privacy Policy
                            </a>
                            , and{" "}
                            <a
                              className="underline text-orange-500 hover:cursor-pointer"
                              href="/returnRefund"
                              target="_new"
                            >
                              Return & Refund Policy
                            </a>{" "}
                          </span>
                        </label>
                      </div>
                      {paymentType !== "Pay" &&
                        cart.some((type) => type.orderType !== "Pre-Order") ? (
                        <button
                          className={`btn btn-primary ${paymentLoading ? "hidden" : "block"
                            }`}
                          disabled={!polecy}
                        >
                          Confirm Order
                        </button>
                      ) : (
                        <button
                          className={`btn btn-primary ${paymentLoading ? "hidden" : "block"
                            }`}
                          disabled={!polecy}
                        >
                          Payment Now
                        </button>
                      )}
                    </>
                  )}

                {paymentLoading && (
                  <div className="py-4">
                    <ButtonSpinner></ButtonSpinner>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      {addressUpdate && (
        <AddressUpdate
          refetch={refetch}
          refetchss={refetchss}
          setSingleCity={setSingleCity}
          singleCity={singleCity}
          addressUpdate={addressUpdate}
          setAddressUpdate={setAddressUpdate}
        ></AddressUpdate>
      )}
    </div>
  );
};

export default ViewCart;
