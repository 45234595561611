import React from 'react';

const WishListProduct = ({ product, handelDeleteWishList }) => {
    return (
        <tr>

            <td>
                <div className="flex items-center space-x-3">
                    <div className="avatar">
                        <div className="mask mask-squircle w-12 h-12">
                            <img src={`https://api.shopinshop.com.bd/${product.primaryImage}`} alt="Avatar Tailwind CSS Component" />
                        </div>
                    </div>
                    <div>
                        <div className="font-bold">{product.productName}</div>
                        <div className="text-sm opacity-50">{product.orderType}</div>
                    </div>
                </div>
            </td>
            <td>
                {product.marchentShop}
                <br />
                <span className="badge badge-ghost badge-sm">{product.marchentEmail}</span>
            </td>

            {
                product?.sPrice ? <td>{product?.sPrice} &#2547;  <del>{product.price}</del> &#2547;</td> : <td>{product?.price} &#2547;  </td>
            }

            <th>

                <i onClick={() => handelDeleteWishList(product._id)} className="hover:text-orange-500 cursor-pointer far fa-trash-alt"></i>

            </th>
        </tr>
    );
};

export default WishListProduct;