import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const ReturnRefundPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className="px-2 md:px-12 py-10">
      <Helmet>
        <title>Shop In Shop | Return & Refund policy</title>
      </Helmet>
      <div className="text-center text-3xl font-bold">
        Return & Refund policy{" "}
      </div>
      {/* <p className='text-xl font-bold my-2'>Return & Refund Policy  </p> */}
      <p className="text-sm ml-6 font-bold my-2">Return Policy: </p>
      <ul className="ml-10">
        <li className="list-disc mb-2">
          If your product is damaged, defective, incorrect or incomplete at
          the time of delivery, please send it right away with our delivery
          man. In case not performing the immediate method you will get a 7
          days easy return policy by informing us via our helpline.{" "}
        </li>
        <li className="list-disc mb-2">
          Customers have to pay product return charge for 7 days return policy
        </li>
        <li className="list-disc mb-2">
          For selected categories, we accept a change of mind: Men’s Fashion,
          Women’s Fashion, Men’s bags, Women’s bags, Luggage & Suitcase,
          Bedding, and Bath. Exceptions are women's intimate apparel, men's
          innerwear, swimwear, eyewear, jewelry, watches, and shoe-care.
        </li>
        <li className="list-disc mb-2">
          For device related issues after usage or the expiration of the
          return window, seller warranty or brand warranty could be given by
          the seller. For seller warranty, please contact the seller. The
          contact details of the seller can be found on the invoice. For brand
          warranty, please refer to the Brand Contact List. For more
          information on warranty claims please view our Warranty Policy.
        </li>
        <li className="list-disc mb-2">
          For device related issues after usage or the expiration of the
          return window, seller warranty or brand warranty could be given by
          the seller. For seller warranty, please contact the seller. The
          contact details of the seller can be found on the invoice. For brand
          warranty, please refer to the Brand Contact List. For more
          information on warranty claims please view our Warranty Policy
        </li>
      </ul>

      <p className="text-sm ml-6 font-bold my-2">7 Days Return Policy:</p>
      <ul className="ml-10">
        <li className="list-disc mb-2">
          Customers have to notify us within 7 days for exchange policy. If a
          customer does not like the product, size issue or color issue
          happens then the customer have to pay extra 60 taka delivery charge
          to exchange the product.{" "}
        </li>
        <li className="list-disc mb-2">
          In case of a wrong or damaged product shopinshop.com.bd will
          exchange the product free of charge.
        </li>
        <li className="list-disc mb-2">
          In case of claiming the free exchange policy, customers need to
          check the product when delivery happens and claim it right away.
        </li>
      </ul>

      <p className="text-sm ml-6 font-bold my-2">
        Valid Reasons to Return an Item:
      </p>
      <ul className="ml-10">
        <li className="list-disc mb-2">
          Delivered Product is damaged (physically destroyed or broken) /
          defective (not working on arrival)
        </li>
        <li className="list-disc mb-2">
          Delivered Product is incorrect (presentation different on website) /
          incomplete (missing parts){" "}
        </li>
        <li className="list-disc mb-2">
          Delivered Product is “No longer needed” (you no longer have a use
          for the product / you have changed your mind about the purchase /
          the size of a fashion product does not fit / you do not like the
          product after opening the package) - eligible for selected products
          only. Customers cannot refund the delivery cost.
        </li>
      </ul>
      <p className="text-sm ml-6 font-bold my-2">Refund Policy</p>
      <p className="ml-10">
        If your product is eligible for a refund, you can choose your
        preferred refund method based on the table below. The shipping fee
        will not be refunded along with the amount paid for your returned
        product. The time required to complete a refund depends on the refund
        method you have selected. Once we have received your product (2-3
        working days) and it has undergone a quality control (1-2 working
        days), the expected refund processing times are as follows: Please
        note that this policy does not apply to Shop In Shop pre-order
        products.
      </p>
      <table className="ml-10 mt-4" style={{ border: "1px solid black" }}>
        <tr style={{ border: "1px solid black" }}>
          <th style={{ border: "1px solid black" }}>Payment Method </th>
          <th style={{ border: "1px solid black" }}>Refund Option</th>
          <th style={{ border: "1px solid black" }}>Refund Time </th>
        </tr>
        <tr>
          <td style={{ border: "1px solid black" }}>Bkash</td>
          <td style={{ border: "1px solid black" }}>Bkash</td>
          <td style={{ border: "1px solid black" }}>2-3 Business Days </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid black" }}>Online Payment </td>
          <td style={{ border: "1px solid black" }}>Online Payment</td>
          <td style={{ border: "1px solid black" }}>7-10 Business Days</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid black" }}>Cash On Delivery(COD)</td>
          <td style={{ border: "1px solid black" }}>
            Bkash/Online Payment Rfund
          </td>
          <td style={{ border: "1px solid black" }}>2-3 Business Days</td>
        </tr>
      </table>

      <p className="mt-2">
        <b>NOTE: </b> Your refund request might be cancelled/ disqualified for
        the following reason:
      </p>
      <ul className="ml-10">
        <li className="list-disc mb-2">Customer phone was unreachable</li>
        <li className="list-disc mb-2">
          The delivery was rescheduled by the customer{" "}
        </li>
      </ul>
    </div>
  );
};

export default ReturnRefundPolicy;