import parser from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { toast } from "react-toastify";
import { useMedia } from "react-use-media";
import ProductCart from "../../Pages/Cart/ProductCart";
import Spinner from "../../Shared/Spinner/Spinner";
import { addToCartLocal, getShoppingCart } from "../../Utilitis/addToCartLocal";
import { buyNow } from "../../Utilitis/buyNow";
import MobileProductCart from "../Cart/MobileProductCart";
import ProductButton from "./ProductButton";
import "./SingleProduct.module.css";
import YoutubeVideo from "./YoutubeVideo";


const SingleProduct = () => {
  const nagigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [nagigate]);

  const { productId } = useParams();
  const [reviews, setReview] = useState([]);
  const [rating, setRating] = useState(0);
  const [copySuccess, setCopySuccess] = useState("");
  const [pQuentity, setPQuentity] = useState(1);
  const [mainImages, setMainImages] = useState("");
  const [openOutlet, setOutlet] = useState(false);
  const [cart, setCart] = useState([]);
  const [openVideo, setOpenVideo] = useState(false);
  const [categorySingleProduct, setSingleCategory] = useState([]);
  const extraSmall = useMedia("(max-width : 450px)");
  const isMobile = useMedia("(max-width: 700px)");
  const tab = useMedia("(min-width: 700px) and (max-width: 1000px)");
  const largePc = useMedia("(min-width: 1000px) and (min-width: 1800px)");


  const { isLoading, data, refetch } = useQuery(["product", productId], () =>
    fetch(
      `https://api.shopinshop.com.bd/product/client/public/${productId}`
    ).then((res) => res.json())
  );
  // console.log(data)
  const percentage = ((data?.price - data?.sPrice) / data?.sPrice) * 100 || "0";
  // console.log(percentage)
  useEffect(() => {
    fetch(`https://api.shopinshop.com.bd/productReview`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data?.review),
    })
      .then((res) => res.json())
      .then((result) => {
        setReview(result?.review);
        setRating(result?.avgRating);
      });
  }, [data]);

  useEffect(() => {
    const storedCart = getShoppingCart();
    const saveCart = [];
    for (const id in storedCart) {
      const storeProduct = [data]?.find((product) => product?._id === id);
      if (storeProduct) {
        const quantity = storedCart[id];
        storeProduct.quantity = quantity;
        saveCart.push(storeProduct);
      }
    }
    setCart(saveCart);
  }, [productId, pQuentity, data]);

  // console.log("hello",data?.mainCategoryID[0]?.value)
  const handelSingleProduct = (id) => {
    navigate(`/singleProduct/${id}`);
  };

  useEffect(() => {
    fetch(
      `https://api.shopinshop.com.bd/single-product/category/${data?.mainCategoryID[0]?.value}`
    )
      .then((res) => res.json())
      .then((result) => {
        setSingleCategory(result);
      });
  }, [data]);

  const handelDiscrese = () => {
    if (+pQuentity > 1) {
      setPQuentity(+pQuentity - 1);
    }
  };
  const handelIncrese = () => {
    if (+pQuentity < +data?.quantity) {
      setPQuentity(+pQuentity + 1);
    }
  };

  const quantityHandeler = (event) => {
    const current = event.target.value;
    if (+data?.quantity < current) {
      toast.error(`Please select less than ${data?.quantity}`);
    } else {
      setPQuentity(current);
    }
  };

  const handelImage = (imgId) => {
    const mainImage = `https://api.shopinshop.com.bd/${imgId}`;
    setMainImages(mainImage);
  };

  const navigate = useNavigate();

  const handelBuyProduct = (event) => {
    event.preventDefault();
    const newQuentity = event.target.number.value;
    const color = event.target.color?.value || "";
    const size = event.target.size?.value || "";
    const buyInfo = { productId, newQuentity, color, size };
    // console.log(size);
    if (data.color && data.size) {
      if (color && size) {
        buyNow(buyInfo);
        navigate(`/checkOut/${data._id}`);
      }
      else {
        toast.error("Please select color and size");
      }
    }
    else if (data?.color) {
      if (color) {
        // console.log("size", true);
        buyNow(buyInfo);
        navigate(`/checkOut/${data._id}`);
      } else {
        toast.error("Please select color");
      }
    }
    else if (data?.size) {
      if (size) {
        // console.log("size", true)
        buyNow(buyInfo);
        navigate(`/checkOut/${data._id}`);
      } else {
        toast.error("Please select size");
      }
    }
    else {
      buyNow(buyInfo);
      navigate(`/checkOut/${data._id}`);
    }
  };


  if (isLoading) {
    return <Spinner></Spinner>;
  }

  const handelCart = (product, refetch) => {
    let newCart = [];
    const exists = cart.find((cartProduct) => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newCart = [...cart, product];
    } else {
      const rest = cart.filter(
        (cartProduct) => cartProduct._id !== product._id
      );
      product.quantity += 1;
      newCart = [...rest, exists];
    }

    if (!isLoading) {
      setCart(newCart);
      addToCartLocal(product._id, refetch);
      refetch();
    }
  };

  return (
    <div>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={data?.productName} />
        <meta property="og:description" content={data.shortDescription} />
        <meta name="description" content={data.shortDescription} />
        <meta property="og:url" content="shopinshop.com.bd" />
        <meta
          property="og:image"
          content={`https://api.shopinshop.com.bd/${data?.primaryImage}`}
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="628" />
        <meta property="og:image:height" content="600" />
        <meta property="og:image:alt" content="product Image" />
        <title>Shop In Shop | {data?.productName}</title>
      </Helmet>

      <div className="grid grid-cols-1 lg:grid-cols-4  mt-10 text-start px-5 md:px-12">
        <div className="col-span-2">
          <div className="flex justify-center md:w-[600px] md:h-[400px] rounded-lg">
            <div className="w-full md:w-[400px] md:h-[600px]  ml-2 text-center">
              <InnerImageZoom
                zoomScale={1}
                zoomType="hover"
                zoomPreload={true}
                fadeDuration={150}
                hideHint={true}
                src={
                  mainImages ||
                  `https://api.shopinshop.com.bd/${data?.primaryImage}`
                }
                zoomSrc={
                  mainImages ||
                  `https://api.shopinshop.com.bd/${data?.primaryImage}`
                }
              />
            </div>
          </div>

          <div className="flex justify-center mt-2 gap-4">
            {
              <div
                onClick={() => handelImage(data?.primaryImage)}
                className="cursor-pointer"
              >
                <img
                  className="border-2 w-[60px] md:w-[80px]"
                  src={`https://api.shopinshop.com.bd/${data?.primaryImage}`}
                  alt=""
                />
              </div>
            }
            {data?.secondImage?.map((img, index) => (
              <div
                onClick={() => handelImage(img)}
                key={index}
                className="cursor-pointer"
              >
                <img
                  className="border-2 w-[60px] md:w-[80px]"
                  src={`https://api.shopinshop.com.bd/${img}`}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mt-6 md:mt-0 px-2 col-span-2">
          <h2 className="text-xl md:text-2xl font-semibold capitalize">
            {data?.productName}
          </h2>
          <div className="mt-2 text-xs">
            <StarRatings
              rating={rating || 0}
              starRatedColor="orange"
              starDimension="20px"
              starSpacing="1px"
            />
          </div>
          <form onSubmit={handelBuyProduct}>
            <div className="mt-2">
              {data?.sPrice ? (
                <div className="flex flex-wrap gap-4">
                  <div>
                    <p>
                      <span className="text-xl me-2 text-red-500 ">-{Math.round(percentage)}%</span>
                      {/* {" "}
                    <span className="line-through">
                      {Math.round(data?.price)} &#2547;
                    </span>{" "} */}
                      <span className="text-2xl font-bold">
                        {Math.round(data?.sPrice)} &#2547;
                      </span>{" "}
                    </p>
                    <p>M.R.P
                      {" "}
                      <span className="line-through">
                        {Math.round(data?.price)} &#2547;
                      </span>{" "}
                    </p>
                    <p><span className="text-sm">Inclusive of all taxes.</span></p>
                  </div>
                  <div className="border border-2 rounded m-2 p-3">
                    <p><span className="font-medium">For Pre order</span> Estimate Delivery in 12-14 Working Days From India.</p>
                    <p><span className="font-medium">For Stock Estimate</span> Delivery in 1-5 Working Days From Dhaka.</p>
                  </div>
                </div>
              ) : (
                <div className="">
                  <p>
                    <span className=" font-bold text-2xl">
                      {data.price} &#2547;
                    </span>{" "}
                  </p>
                </div>
              )}

              <p className="my-4 pr-4">{data.shortDescription}</p>
              <p>
                SKU: <span className="font-semibold text-sm">{data.sku}</span>
              </p>
              <p>
                CATEGORY:{" "}
                {Array.isArray(data?.mainCategoryID)
                  ? data?.mainCategoryID?.slice(0, 4).map((cat, index) => (
                    <span key={index} className="font-semibold text-sm">
                      {" "}
                      {cat.label}{" "}
                    </span>
                  ))
                  : data.mainCategoryID.label}
              </p>
              <p>
                BRAND:{" "}
                <span className="font-semibold text-sm">
                  {data.brand || "No brand"}
                </span>
              </p>

              {data?.color && (
                <div className="flex flex-wrap my-2 items-center">
                  <p>COLOR: </p>

                  <select
                    name="color"
                    className="select select-bordered select-xs ml-2"
                  >
                    <option value='' disabled selected>
                      Select Color
                    </option>
                    {data.color &&
                      data.color
                        .split(",")
                        .map((color, index) => (
                          <option key={index}>{color}</option>
                        ))}
                  </select>
                </div>
              )}

              {data?.size && (
                <div className="flex flex-wrap items-center">
                  <p>SIZE: </p>

                  <select
                    name="size"
                    className="select select-bordered select-xs ml-2"
                  >
                    <option value="" disabled selected>
                      Select Size
                    </option>
                    {data.size &&
                      data.size
                        .split(",")
                        .map((size, index) => (
                          <option key={index}>{size}</option>
                        ))}
                  </select>

                  {/* {data.size &&
                    data.size.split(",").map((size, index) => (
                      <div key={index} className="flex items-center ml-2">
                        <input
                          type="radio"
                          value={size}
                          className="radio w-4 h-4 mr-2 cursor-pointer"
                          name="size"
                        />
                        <label className="text-sm font-semibold ">{size}</label>
                      </div>
                    ))} */}
                </div>
              )}
              {data.quantity > 0 && (
                <span className="font-semibold text-md text-orange-500">
                  {data.quantity > 0 && data.orderType !== "Pre-Order"
                    ? "In stock"
                    : "Pre-Order"}
                </span>
              )}

              {data?.productPromo && (
                <div className="mt-4 flex flex-wrap gap-4">
                  {data?.productPromo?.slice(0, 5).map((promo, index) => (
                    <img
                      title={promo.label}
                      key={index}
                      className="mask mask-circle w-10 md:w-20"
                      src={promo.value}
                      alt="promo images"
                    />
                  ))}
                </div>
              )}
            </div>

            <hr className="w-11/12 my-4" />
            <div className="lg:flex items-center">
              <div className="flex items-center">
                <div className="flex mx-auto">
                  <div
                    onClick={handelDiscrese}
                    className="flex items-center justify-center cursor-pointer btn text-black hover:text-white p-2 text-xl bg-slate-100 w-10 rounded-lg"
                  >
                    -
                  </div>
                  <input
                    onChange={quantityHandeler}
                    name="number"
                    type="number"
                    max={data.quantity}
                    value={pQuentity}
                    min="1"
                    className="outline-none text-xl h-10 w-10 mx-2 text-center"
                  />
                  <div
                    onClick={handelIncrese}
                    className="flex items-center justify-center cursor-pointer btn text-black hover:text-white p-2 text-xl bg-slate-100 w-10 rounded-lg"
                  >
                    +
                  </div>
                </div>
              </div>

              <div className="flex mx-auto m-4">
                <div className="flex mx-auto">
                  <div className="ml-6">
                    <button type="submit" className="btn btn-md btn-primary">
                      {/* <i className="mr-4 far fa-credit-card" /> */}
                      <p>BUY NOW</p>
                    </button>
                  </div>
                  <div className="ml-6">
                    <span
                      onClick={() => {
                        handelCart(data, refetch);
                        // navigate("/allProduct");
                      }}
                      className="btn btn-primary w-full"
                    >
                      {/* <i className="mr-4 fas fa-cart-plus" /> */}
                      <p>Add to cart</p>
                    </span>
                  </div>
                </div>
              </div>

              {data?.video && (
                <div
                  className="ml-6 tooltip tooltip-secondary"
                  data-tip="Play Product Video"
                >
                  <label htmlFor="youtube-modal">
                    {" "}
                    <i
                      onClick={() => setOpenVideo(data?.video)}
                      className="text-red-500 text-2xl cursor-pointer fa-solid fa-circle-play"
                    ></i>
                  </label>
                </div>
              )}
            </div>
            <hr className="w-11/12 mt-4" />
          </form>
          {/* {window.location.href */}
          <div className="flex flex-wrap">
            <div className="mt-4 flex justify-start items-center gap-4">
              <p className="uppercase font-bold">Copy link : </p>
              {/* <a
              href={`whatsapp://send?text= Please Visit ${window.location.href}`}
              rel="noreferrer"
              target="_blank"
              className="share-icon"
            >
              <i className="text-[20px] hover:text-orange-500 fa-brands fa-whatsapp"></i>
            </a>
            <a
              href={`http://www.facebook.com/dialog/send?
  app_id=123456789
  &amp;link=http://www.nytimes.com/interactive/2015/04/15/travel/europe-favorite-streets.html
  &amp;redirect_uri=${window.location.href}`}
              rel="noreferrer"
              target="_blank"
              className="share-icon"
            >
              <i className="text-[20px] hover:text-orange-500 fa fa-facebook"></i>
            </a> */}
              <button
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  setCopySuccess("Copied!");
                  // setTimeout(setCopySuccess(''), 5000)
                  setTimeout(() => setCopySuccess(""), 3000);
                }}
                className="share-icon"
              >
                <i className="text-[20px] hover:text-orange-500 fas fa-link"></i>
              </button>

              <p className="text-orange-500">{copySuccess}</p>
            </div>
            <div className="mt-4">
              <ProductButton></ProductButton>
            </div>
          </div>
        </div>
      </div>

      {openVideo && <YoutubeVideo openVideo={openVideo}></YoutubeVideo>}

      <div className="px-5 md:px-10 mt-4">
        <div className="flex items-center justify-center">
          <button
            onClick={() => setOutlet(false)}
            className={`font-bold ${!openOutlet ? "underline underline-offset-8" : ""
              }`}
          >
            Description
          </button>
          <button
            onClick={() => setOutlet(true)}
            className={`ml-6 font-bold ${openOutlet ? "underline underline-offset-8" : ""
              }`}
          >
            Reviews
          </button>
        </div>

        <div className="mt-6">
          {openOutlet ? (
            <div className="pb-6">
              {reviews ? (
                reviews?.map((review) => (
                  <div
                    key={reviews._id}
                    className="flex shadow-lg mb-6 p-4 rounded-lg items-center"
                  >
                    <div className="w-1/2">
                      <h2 className="text-xl font-semibold">{review.name}</h2>
                      <div className="mt-2 text-xs">
                        {[...Array(review?.rating)].map((el, index) => (
                          <i
                            key={index}
                            className="text-orange-500 fas fa-star"
                          ></i>
                        ))}
                      </div>
                    </div>
                    <div className="px-10">
                      <p>{review.comment}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-orange-500 font-bold">
                  There aren't any reviews for this product yet!
                  <br />
                  <button
                    className="hover:underline btn mt-2 btn-primary"
                    onClick={() => nagigate("/profile/order")}
                  >
                    Submit your review
                  </button>
                </p>
              )}
            </div>
          ) : (
            <div>
              <div className="pb-6 flex justify-center">
                <div className="w-full">{parser(data?.logDescription)}</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="text-center font-bold uppercase underline mt-16">
          Related Products
        </div>

        <div
          className={`gap-5 mb-10 grid px-5 justify-center ${isMobile
            ? "grid-cols-2"
            : largePc
              ? "grid-cols-6"
              : tab
                ? "grid-cols-3"
                : "grid-cols-4"
            }`}
        >
          {categorySingleProduct?.map((product) =>
            extraSmall ? (
              <MobileProductCart
                product={product}
                handelWhishList={() => false}
                handelSingleProduct={handelSingleProduct}
                handelCart={handelCart}
                isMobile={isMobile}
              ></MobileProductCart>
            ) : (
              <ProductCart
                product={product}
                handelWhishList={() => false}
                handelSingleProduct={handelSingleProduct}
                handelCart={handelCart}
                isMobile={isMobile}
              ></ProductCart>
            )
          )}
        </div>

        <Link
          to={`/main-category/${data?.mainCategoryID[0]?.value}?name=''&banner=${false}`}
          className="flex justify-center my-4 text-orange-500"
        >
          See More
        </Link>
      </div>
    </div>
  );
};

export default SingleProduct;
