import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import CookiesImg from '../../Images/other_image/chookies.png';
const Cookies = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
      <div className="container mx-auto px-12 md:px-0 flex justify-center my-10">
        <Helmet>
          <title>Shop In Shop | Cookie Policy</title>
        </Helmet>
        <img src={CookiesImg} alt="Cookies Pilicy" />
      </div>
    );
};

export default Cookies;