import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const navigate = useNavigate();
    return (
      <div className="px-12 my-10">
        <Helmet>
          <title>Shop In Shop | Terms & Conditions</title>
        </Helmet>
        <h2 className="text-2xl font-bold">Terms & Conditions </h2>
        <p>
          Shopinshop.com.bd is an online shopping platform through which
          customers have the luxury of being able to complete the entire buying
          process from their homes. Before accessing our services, the customer
          must agree to comply with the ‘Terms and Conditions’. Said ‘Terms and
          Conditions’ apply to Shopinshop.com.bd as a Site, and to the customer
          to govern the usage of the website and the right of usage. By
          accessing (browsing, registering or submitting any material) the Site
          you confirm your understanding of and acceptance of these ‘Terms and
          Conditions’ in full; accordingly, if you disagree with these ‘Terms
          and Conditions’ or any specific part of these terms and conditions,
          you must not use our website. Shopinshop.com.bd reserves the right to
          change, modify, add or remove any part of the ‘Terms and Conditions’
          without providing an update, notice or justification. Changes will be
          in usage from the time of publishing on Shopinshop.com.bd under ‘Terms
          and Conditions’.
        </p>
        <p className="text-md font-bold my-4">Copyright Clause:</p>
        <p>
          All content included on or comprising the Shopinshop.com.bd
          Properties, including information, data, software, photographs,
          graphs, videos, typefaces, graphics, music, sounds, and other material
          (collectively “Content”) is protected by copyright, trademark, patent,
          or other proprietary rights, and these rights are valid and protected
          in all forms, media and technologies existing now or developed in the
          future. All Content is protected as a collective work under
          Bangladesh.
        </p>
        <p className="text-md font-bold my-6">For The Customers: </p>
        <ul className="ml-10">
          <li className="list-disc mb-2">
            Our website uses cookies; by using our website or agreeing to these
            ‘Terms and conditions’, you consent to our use of cookies in
            accordance to that which has been outlined in the ‘Privacy Policy’.
          </li>
          <li className="list-disc mb-2">
            The customer must provide legitimate information while creating a
            user account. Any submission of information deemed by
            Shopinshop.com.bd to be illegitimate or inapplicable may result in
            termination of the account and the inability to access the Site
            again.
          </li>
          <li className="list-disc mb-2">
            As highlighted in the ‘Privacy Policy’, the customer bears sole
            liability for activity from their user ID. Appropriate action in
            case of unauthorized activity is also outlined in the ‘Privacy
            Policy’.
          </li>
          <li className="list-disc mb-2">
            The customer must be at least 15 years old or accessing the site
            under the supervision of a parent or legal guardian.
          </li>
          <li className="list-disc mb-2">
            Usage of the Site on behalf of a third party is not permissible
          </li>
          <li className="list-disc mb-2">
            Presentation of the products consists of informational content by
            the vendor/merchant. Product content is not opinionated or biased
            and any submissions or opinions expressed are individual posts or
            reviews by users for which Shopinshop.com.bd does not bear
            liability.
          </li>
          <li className="list-disc mb-2">
            Any data submission and usage of the Site is subject to
            documentation for information by Shopinshop.com.bd as outlined in
            the ‘Privacy Policy’
          </li>
          <li className="list-disc mb-2">
            The customer, upon agreement of the ‘Terms and Conditions’ earns
            usage rights of the site within the parameters outlined and defined
            by Shopinshop.com.bd The user may browse the site, print pages, save
            content, stream audio or video
          </li>
          <li className="list-disc mb-2">
            Unless controlling or owning relevant rights, the user may not sell,
            rent or sub-license material from our website, show any material
            from our website in public, exploit material from our website for a
            commercial purpose or redistribute material from the Site.
          </li>
          <li className="list-disc mb-2">
            The customer may not conduct any activity that may result in damage
            to the website or impairment of the performance, availability or
            accessibility of the website. Usage of the Site in any manner that
            is unlawful, illegal, fraudulent of harmful or in connection with
            any of the aforementioned, is not allowed
          </li>
          <li className="list-disc mb-2">
            Use of our website to copy, store, host, transmit, send, use,
            publish or distribute any material which consists of (or is linked
            to) any spyware, computer virus, Trojan horse, worm, keystroke
            logger, rootkit or other malicious computer software is disallowed.
            Conducting any systematic or automated data collection activities
            such as and not limited to extraction is illegal. Usage data from
            our website for any direct marketing activity is disallowed
          </li>
          <li className="list-disc mb-2">
            Data from our site may not be used to contact any individuals.
          </li>
          <li className="list-disc mb-2">
            Similarly, any activity that Shopinshop.com.bd deems to be
            inappropriate (without explanation) can result in disallowance of
            usage
          </li>
          <li className="list-disc mb-2">
            Given extenuating circumstances, Shopinshop.com.bd may cancel or
            refuse to provide the service at any given time without explanation
            or justification. This may occur in place of and is not limited to
            occasions in which the order could not be processed. Similarly, in
            case of technical errors such as and not limited to, the price of an
            item being incorrectly displayed; Shopinshop.com.bd reserves the
            rights to order cancelation
          </li>
          <li className="list-disc mb-2">
            Lack of compliance with any of the rules stated above may result in
            ceased access to the website and possibly legal action.
          </li>
        </ul>

        <p className="text-md font-bold my-4">Paying for your order:</p>
        <p>
          Terms and Conditions about our payment and delivery policies, Return&
          Refund policies are included in the{" "}
          <span
            onClick={() => navigate("/shipping")}
            className="hover:underline text-orange-500 cursor-pointer"
          >
            Shipping Policy
          </span>{" "}
          . For further details please click on the given link{" "}
        </p>
      </div>
    );
};

export default Terms;