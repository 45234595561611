import React from 'react';
import { useForm } from 'react-hook-form';

const Feedback = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch, reset
    } = useForm();

    const onSubmit = (data) => {
        // console.log(data);
        sentToWhatsapp(data);
        reset()
    };

    function sentToWhatsapp(data) {
        const number = "+8801778057784";
        const name = data.name;
        const feedback = data.feedback;
        const pageLink = window.location.href;

        const url = "https://wa.me/" + number + "?text=" +
            "Name: " + name + "%0a" +
            "Page Link: " + pageLink + "%0a" +
            "Feedback: " + feedback + "%0a%0a";

        window.open(url, '_blank').focus();
    }

    const name = watch("name", "");
    const feedback = watch("feedback", "");

    return (
        <div className="min-h-full flex items-center justify-center">
            <div className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 mx-auto p-4 bg-white rounded-lg shadow-lg">
                <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <h2 className="text-2xl font-bold mb-4 text-center">Complain Us</h2>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Name
                        </label>
                        <input
                            type="text"
                            placeholder="Your Name"
                            className="input input-bordered w-full"
                            {...register("name", {
                                required: {
                                    value: true,
                                    message: "Name is required",
                                },
                            })}
                        />
                        {errors.name?.type === "required" && (
                            <p className="text-red-500 text-xs mt-1">
                                {errors.name.message}
                            </p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Complain Us
                        </label>
                        <textarea
                            type="text"
                            placeholder="Complain Us"
                            className="textarea textarea-bordered w-full h-{50px}"
                            {...register("feedback", {
                                required: {
                                    value: true,
                                    message: "Complain is required",
                                },
                            })}
                        />
                        {errors.feedback?.type === "required" && (
                            <p className="text-red-500 text-xs mt-1">
                                {errors.feedback.message}
                            </p>
                        )}
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary">
                            Send Your Complain
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Feedback;
