import React from "react";

const SocialLogin = ({ signInWithGoogle }) => {
  return (
    <div className="w-full">
      <button
        onClick={() => signInWithGoogle()}
        className="btn w-full bg-secondary mb-4  capitalize"
      >
        <div className="flex">
          <i className="fa-brands fa-google"></i>
          <p className="ml-2">Login With Google</p>
        </div>
      </button>
      <button className="btn w-full bg-secondary   capitalize">
        <i className="fa-brands fa-facebook-f"></i>
        <p className="ml-2">Login With facebook</p>
      </button>
    </div>
  );
};

export default SocialLogin;
